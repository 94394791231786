import React, { 
    FC, 
    useEffect, 
    useState,
} from 'react';

import { styled } from 'styled-components';
import { 
    Heading, 
    Hours, 
} from './DayView';
import { colors } from '../../../styles/colors';
import { 
    IDayView, 
    TestDiv, 
    getWeek, 
} from './Calendar.utils';
import ITag from '../../../types/Tag.types';
import { addZeroIfNeeded } from './Calendar';
import { onlyUnique } from './MonthView';

export const WeekView: FC<IDayView> = ({
    date,
    items,
}) => {

    const days: Date[] = getWeek(date);
    const [finalItems, setFinalItems] = useState<any[]>([]);

    useEffect(() => {
        const internalItems: any[] = [];
        items.map(item => {
            const date = item?.date;
            const nick = item?.nick;
            const hourMinutePart = date?.split(' ')[1].split(':');
            const moveY = (hourMinutePart?.[0] * 42 + hourMinutePart?.[1] * 42/60 - 21) || 0;
            item?.tags?.filter((innerTag: ITag) => !!innerTag).map((tag: ITag) => {
                internalItems.push({
                    nick: nick,
                    tag: tag,
                    date: date,
                    moveY: moveY,
                    height: 42,
                    idx: days.indexOf(days?.filter(day => date?.startsWith(day.getFullYear() + "-" + addZeroIfNeeded(day.getMonth() + 1) + "-" + addZeroIfNeeded(day.getDate())))?.[0])
                })
            });
        });

        let internalFinalItems: any[] = [];
        days.forEach((internalDay, idx) => {
            const list = internalItems?.filter(internalItem => internalItem.idx === idx).sort((item1, item2) => item1.moveY - item2.moveY);
            let newItem = {
                tag: list?.[0]?.tag,
                date: date,
                moveY: list?.[0]?.moveY,
                height: 42,
                idx: list?.[0]?.idx,
                nicks: [list?.[0]?.nick]
            };
            for (let i = 1; i < list.length; i++) {
                const internalItem = list[i];
                const internaHeight = (internalItem?.moveY + internalItem?.height) - (newItem?.moveY + newItem?.height);
                if (internaHeight >= 0 && internaHeight <= 42) {
                    newItem.nicks.push(internalItem?.nick);
                    newItem.height += internaHeight; 
                } else {
                    internalFinalItems.push(newItem);
                    newItem = {
                        tag:  internalItem?.tag,
                        date: date,
                        moveY: internalItem?.moveY,
                        height: 42,
                        idx: internalItem?.idx,
                        nicks: [internalItem?.nick]
                    };
                }
            }
            internalFinalItems.push(newItem);
        });

        setFinalItems(internalFinalItems);
    }, [items]);

    return (<><Row>
        {
            days.map(day => (<div>
                <Heading bgColor={false}>
                    <span>{ day.toLocaleString('en-US', {weekday: 'short'}) }</span>
                    <span>{ day.toLocaleString('en-US', {day: 'numeric'}) }</span>
                </Heading>
            </div>))
        }
            
        </Row>
        <Hours>
        {
            Array.from(Array(24).keys()).map(hour => (<div>
                <span>{hour}</span>
            </div>))
        }
        </Hours>
        { finalItems.map(item => (<TestDiv 
                moveX={item?.idx} 
                moveY={item?.moveY}
                width={132}
                height={item?.height}
                bgColor={item?.tag?.bgColor}
                color={item?.tag?.color}>
                <div>{ item?.nicks?.filter(onlyUnique)?.length }</div>
            </TestDiv>))
        }
    </>);
};

const Row = styled.div`
    display: flex;
    padding-left: 64px;
    background-color: ${colors.white};
`;