import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const MainDiv = styled.div`
    margin: 8px auto;
    align-items: center;
    justify-content: center;
    color: ${colors.whiteStrong};
    display: flex;
    width: 1800px;
    @media (max-width: 2000px) {
        width: calc(100% - 64px);
    }
`

export const PagesWrapper = styled.div<{ withSize?: boolean }>`
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    width: fit-content;
`;

export const PageItem = styled.div<{ isSelected?: boolean, paddingLeft?: boolean, paddingRight?: boolean }>`
    cursor: pointer;
    text-decoration: ${p => p.isSelected && 'underline'};
    font-weight: ${p => p.isSelected && '700'};
    padding-left: ${p => p.paddingLeft && '16px'};
    padding-right: ${p => p.paddingRight && '16px'};

    &:hover {
        opacity: 0.7;
    }
`;

export const SizeWrapper = styled.div`
    position: absolute;
    right: 62px;
    display: flex;
    gap: 16px;
    font-size: 16px;
    align-items: center;

    > div {
        width: 90px;
        color: ${colors.black};
    }
`