import React, { FC, useEffect, useState } from 'react';

import { 
    IGroup, 
    IUser, 
} from '../../../types/IGroup.types';
import { styled } from 'styled-components';
import { colors } from '../../../styles/colors';
import {
    AutoComplete, 
    AutoCompleteInput, 
    AutoCompleteItem, 
    AutoCompleteList 
} from '@choc-ui/chakra-autocomplete';
import icons, { Icon } from '../../commons/Icon';
import { StyledButton } from '../../commons/Button';

export const Group: FC<IGroup> = ({
    uuid,
    users,
    name,
    allUsers,
    removeGroup,
    saveGroup,
    setUsers,
}) => {

    const [actualUserId, setActualUserId] = useState<string | null>(null);
    const [isEditted, setIsEditted] = useState<boolean>(false);
    const [newName, setNewName] = useState<string>(name);

    useEffect(() => {
        setNewName(name);
    }, [name]);

    const user = JSON.parse(sessionStorage?.getItem('user') || '{}');
    const userId = user?.uuid;
    const groupId = user?.groupId;
    const isAdmin = user?.admin;

    return (<GroupBox>
        <div>
            <input type="text" value={newName} disabled={!!uuid && !isEditted} onChange={(e) => {
                (!uuid || isEditted) && setNewName(e?.target?.value);
            }}/>
            { !!uuid && <Icon icon={icons.CloseIcon} onClick={() => removeGroup && removeGroup(uuid)} height={28} /> }
        </div>
        {
            uuid && (<UsersDiv>
            {
                users.map((user: IUser) => (<div>
                    <div>{user.nick}</div>
                    <Icon width={18} icon={icons.TrashIcon} onClick={() => { 
                         fetch(`http://146.59.44.6:8080/user/setGroup${!!groupId ? '?groupId=' + groupId : ''}`, { 
                            method: "POST",
                            mode: 'cors',
                            headers: { 'Content-Type': 'application/json'},
                            body: JSON.stringify({
                                userId: user.uuid,
                                groupId: null
                            })
                        }).then(res => res.text())
                        .then(text => JSON.parse(text))
                        .then(json => {
                            !!setUsers && setUsers((prevState: IUser[]) => {
                                const newState: IUser[] = [];
                                prevState.forEach(internalUser => {
                                    if (internalUser.uuid === user.uuid) {
                                        newState.push(json);
                                    } else {
                                        newState.push(internalUser);
                                    }
                                })
                                return newState;
                            })
                        });
                    }}/>
                </div>))
            }
                <Row>
                    <StyledAutoComplete openOnFocus onChange={(finalUser) => setActualUserId(finalUser)} value={!!actualUserId ? users?.find(user => user.uuid === actualUserId)?.nick : ''}>
                        <AutoCompleteInput variant="filled" height={24} width={310} borderRadius={12} fontSize={12}/>
                        <AutoCompleteList>
                            { 
                                allUsers?.map((finalUser) => (
                                <AutoCompleteItem
                                    fontSize={14}
                                    backgroundColor={colors.white}
                                    color={colors.border}
                                    _hover={{backgroundColor: colors.border, color: colors.white, opacity: 1}}
                                    padding={8}
                                    key={finalUser?.nick}
                                    value={finalUser?.nick}
                                    align="center"
                                >{ finalUser.nick }
                                </AutoCompleteItem>
                                ))
                            }
                        </AutoCompleteList>
                    </StyledAutoComplete>
                    <Icon width={20} icon={icons.MinusIcon} onClick={() => { 
                        setActualUserId(null);
                    }}/>
                    <Icon width={20} icon={icons.AddIcon} onClick={() => {
                        if (!!actualUserId && allUsers) {
                            fetch(`http://146.59.44.6:8080/user/setGroup${!!groupId ? '?groupId=' + groupId : ''}`, { 
                                method: "POST",
                                mode: 'cors',
                                headers: { 'Content-Type': 'application/json'},
                                body: JSON.stringify({
                                    userId: allUsers.filter(user => user.nick === actualUserId)?.[0]?.uuid,
                                    groupId: uuid
                                })
                            }).then(res => res.text())
                            .then(text => JSON.parse(text))
                            .then(json => {
                                !!setUsers && setUsers((prevState: IUser[]) => {
                                    const newState: IUser[] = [];
                                    prevState.forEach(user => {
                                        if (user.nick === actualUserId) {
                                            newState.push(json);
                                        } else {
                                            newState.push(user);
                                        }
                                    })
                                    return newState;
                                });
                                setActualUserId(null);
                            })
                        }
                    }}/>
                </Row>
            </UsersDiv>)
        }
        <ButtonsRow>
            <StyledButton 
                width={150} 
                color={colors.buttons.details} 
                bgColor={colors.buttons.download}
                onClick={() => {
                    if (!!uuid && !isEditted) {
                        setIsEditted(true);
                    } else {
                        !!saveGroup && saveGroup({
                            uuid: uuid,
                            name: newName,
                            users: users
                        })
                        setIsEditted(false);
                    }
                }}>
                { !uuid && !!newName ? 'CREATE' : (isEditted ? 'SAVE' : 'EDIT')}
            </StyledButton>
        </ButtonsRow>
    </GroupBox>);
};

const GroupBox = styled.div`
    padding: 16px;
    background: ${colors.white};
    border: 2px solid ${colors.background};
    width: 400px;

    > div:first-child {
        height: 25px;
        display: flex;
        align-items: center;
        font-size: 16px;
        justify-content: space-between;

        path {
            fill: ${colors.black};
            stroke: ${colors.black};
        }

        input {
            padding: 4px;
            font-size: 16px;
            width: calc(100% - 54px);
        }
    }
`;

const StyledAutoComplete = styled(AutoComplete)`
    font-size: 12px;
    text-align: right;
    box-sizing: border-box;
    padding: 0 4px;
    border-radius: 8px;
`;

const Row = styled.div`
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    svg {
        &:hover {
            opacity: 0.6;
        }
    }

    path {
        fill: ${colors.black};
    }
`;

const ButtonsRow = styled.div`
    margin-top: 16px;
    align-items: center;
    display: flex;
    justify-content: center;

    > button {
        cursor: pointer;
        opacity: 1;

        &:hover {
            opacity: 0.7;
        }
    }
`;

const UsersDiv = styled.div`
    display: flex;
    flex-direction: column;

    > div:first-child {
        margin-top: 8px;
    }

    > div:not(:last-child) {
        padding: 4px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid ${colors.border};

        svg {
            cursor: pointer;
            
            &:hover {
                opacity: 0.8;
            }
        }

        path {
            fill: ${colors.redLight} !important;
            stroke: ${colors.redLight} !important;
        }
    }
`;