import React, { FC } from "react";

import styled from "styled-components";
import { colors } from "../../../styles/colors";
import icons, { Icon } from "../../commons/Icon";

export interface ICalendarHeader {
    title: string;
    type: string;
    setType: (type: string) => void;
    prevDate: () => void;
    nextDate: () => void;
    setToday: () => void;
}

export const CalendarHeader: FC<ICalendarHeader> = ({ 
    title,
    type,
    setType,
    prevDate,
    nextDate,
    setToday,
}) => {
    return (<Container>
        <Row>
            <NowButton onClick={setToday}>TODAY</NowButton>
            <ArrowDiv>
                <Icon icon={icons.LeftArrowIcon} onClick={prevDate} />
                <Icon icon={icons.RightArrowIcon} onClick={nextDate} />
            </ArrowDiv>
        </Row>
        <div>{title}</div>
        <TypeDiv>
            <label>TYPE:</label>
            <select onChange={(e) => setType(e.target.value)} value={type}>
                <option value="day">DAY</option>
                <option value="week">WEEK</option>
                <option value="month">MONTH</option>
                <option value="year">YEAR</option>
            </select>
        </TypeDiv>
    </Container>);
};

const Container = styled.div`
    padding: 8px;
    background-color: ${colors.white};
    display: flex;
    gap: 16px;
    padding: 8px;
    padding-left: 24px;
    align-items: center;
    font-size: 20px;
    line-height: 20px;
    justify-content: space-between;
`;

const NowButton = styled.div`
    padding: 8px 16px;
    background: ${colors.actionBg};
    border-radius: 8px;
    color: ${colors.whiteStrong};
    align-self: flex-start;
    cursor: pointer;
    margin-right: 48px;
    font-size: 14px;

    &:hover {
        opacity: 0.8;
    }
`;

const TypeDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
`;

const ArrowDiv = styled.div`
    display: flex;
    gap: 16px;
`;

const Row = styled.div`
    display: flex;
    gap: 24px;
    align-items: center;

    svg {
        border-radius: 8px;

        &:hover {
            opacity: 0.8;
        }
    }
`;