import React, { 
    FC, 
    useEffect, 
    useState 
} from "react";

import { PlayerHands } from "../PlayerHands/PlayerHands";
import IHand from "../../types/Hand.types";
import styled from "styled-components";
import { HandDetails } from "../HandDetails/HandDetails";
import { Header } from "../Header/Header";
import { SearchBox } from "../SearchBox/SearchBox";
import { PlayerNotes } from "../PlayerNotes/PlayerNotes";
import IPlayer from "../../types/Player.types";
import { colors } from "../../styles/colors";
import { PlayerTags } from "../PlayerTags/PlayerTags";
import { AutoRefreshBox } from "./Hands";
import icons, { Icon } from "../commons/Icon";
import { ACTION } from "../../types/Action.types";
import IPlayerGlobal from "../../types/IPlayerGlobal";
import { useParams } from "react-router";
import { Filters } from "../PlayerHands/Filters/Filters";
import { uploadPSHandHistoryAll } from "../../utils/HandExporter.utils";

export const Players: FC = () => {

    const params = useParams();
    const [selectedStakes, setSelectedStakes] = useState<number[][]>([]);
    const [searchTrigger, setSearchTrigger] = useState<boolean>(false);
    const [heroNick, setHeroNick] = useState(params?.nick || '');
    const [filterHands, setFilterHands] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedPlayerHand, setSelectedPlayerHand] = useState<IHand | null>(null);

    const user = JSON.parse(sessionStorage?.getItem('user') || '{}');
    const userId = user?.uuid;
    const groupId = user?.groupId;
    const isAdmin = user?.admin;
    
    if (!userId) {
        window.location.href = '/login';
    }

    const openDetails = (uuid: string) => {
        setHand(uuid !== undefined ? playerHands.find(hand => hand.uuid === uuid): null);
        setIsDetailsModal(true);
    }

    const onSearch = async (nick: string) => {
        if (!isLoading && !!nick && nick.length > 0) {
            setIsLoading(true);
            const res = await fetch(`http://146.59.44.6:8080/hand?nick=${nick}${!!userId ? '&uuid=' + userId : ''}${!!groupId ? '&groupId=' + groupId : ''}${selectedStakes.length > 0 ? '&stakes=' + selectedStakes.map(stake => stake[0]) : ''}`, { 
                method: "GET",
                headers: { 'Content-Type': 'application/json'}
            });
            const text = await res.text();
            const json = await JSON.parse(text.trim());
            setPlayerHands(json);
            json?.length > 0 ? window.history?.pushState(null, '', `/players/${nick}/hands`) : window.history?.pushState(null, '', `/players`)
            setIsLoading(false);
        }

        document.title = `${nick} - Korean`;
    }

    const [playerHands, setPlayerHands] = useState<IHand[]>([]);
    const [allPlayers, setAllPlayers] = useState<IPlayerGlobal[]>([]);
    const [allTagsIds, setAllTagsIds] = useState<string[]>([]);
    const [hand, setHand] = useState<IHand | null | undefined>(null);
    const [isDetailsModal, setIsDetailsModal] = useState<boolean>(false);

    useEffect(() => {
        fetch(`http://146.59.44.6:8080/player`,{ 
            method: "GET",
            headers: { 'Content-Type': 'application/json'}
        }).then(res => res.ok ? res.json() : [])
        .then(json => {
            setAllPlayers(json);
        });
    }, []);

    useEffect(() => {
        setIsLoading(true);
        const res = fetch(`http://146.59.44.6:8080/hand?nick=${heroNick}${!!userId ? '&uuid=' + userId : ''}${!!groupId ? '&groupId=' + groupId : ''}${selectedStakes.length > 0 ? '&stakes=' + selectedStakes.map(stake => stake[0]) : ''}`, { 
            method: "GET",
            headers: { 'Content-Type': 'application/json'}
        }).then(res => res.text())
        .then(text => JSON.parse(text))
        .then(json => {
            setPlayerHands(json);
            setIsLoading(false);
        });
    }, [searchTrigger]);

    useEffect(() => {
        if (!!heroNick && heroNick.length > 0 && isAdmin) {
            fetch(`http://146.59.44.6:8080/tagPlayer/admin/${heroNick}`,{ 
            method: "GET",
            headers: { 'Content-Type': 'application/json'}
            }).then(res => res.ok ? res.json() : [])
            .then(json => {
                setAllTagsIds(json);
            });
        }
    }, [heroNick]);

    const playerUuid = !!selectedPlayerHand ? selectedPlayerHand?.players?.find((player: IPlayer) => player.nick === heroNick)?.uuid : undefined;

    return (<>
        <Header itemSelected={1} />
        <HandListContainer marginTop={227}>
        {
            isDetailsModal && !!hand && (
            <Container onClick={() => setIsDetailsModal(false)}>
                <HandDetails 
                    reviewed={hand.reviewed}
                    allPlayers={allPlayers}
                    title={hand.title}
                    uuid={hand.uuid}
                    players={hand.players}
                    bb={hand.bb}
                    sb={hand.sb}
                    pot={hand.pot}
                    blind={hand.blind.sort((a, b) => (a?.actionId || 0) - (b?.actionId || 0))}
                    preflop={hand.preflop.sort((a, b) => (a?.actionId || 0) - (b?.actionId || 0))}
                    flop={hand.flop.sort((a, b) => (a?.actionId || 0) - (b?.actionId || 0))}
                    turn={hand.turn.sort((a, b) => (a?.actionId || 0) - (b?.actionId || 0))}
                    river={hand.river.sort((a, b) => (a?.actionId || 0) - (b?.actionId || 0))}
                    board={hand.board} 
                    heroCards={hand.heroCards}
                    date={hand.date}
                    hideDetails={() => setIsDetailsModal(false)}
                    fullRadius={true} 
                    heroNick={heroNick}/>
            </Container>)
        }
            <SearchBox onSearch={onSearch} setHeroNick={setHeroNick} isLoading={isLoading}/>
            {
                playerHands.length > 0 && (<PlayerTags
                    nick={heroNick}
                    allTagsIds={allTagsIds} />)
            }
            {
                playerHands.length > 0 && (<PlayerNotes 
                    nick={heroNick}
                    playerUuid={playerUuid}
                    handsSize={playerHands.length}/>)
                }
            { playerHands.length > 0 && (<AutoRefreshBox width={300}>
                    { filterHands ? (<Icon icon={icons.TickIcon} onClick={() => {}} width={20} stroke={colors.whiteStrong} fill={colors.whiteStrong}/>) : (<div></div>) }
                    <div onClick={(e) => {
                        setFilterHands((prevState: boolean) => !prevState);
                        e.preventDefault();
                    }}>FILTER HANDS - HERO ACTION</div>
                </AutoRefreshBox>)
            }
            <Filters selectedStakes={selectedStakes} setSelectedStakes={setSelectedStakes} setSearchTrigger={setSearchTrigger} />
            { isAdmin && !isLoading && (<IconWrapper onClick={() => uploadPSHandHistoryAll(playerHands)}>
                    <div>DOWNLOAD ALL </div>
                    <Icon fill={colors.whiteStrong} icon={icons.CopyIcon} onClick={() => {}}/>
                </IconWrapper>)
            }
            { !isLoading ? (<PlayerHands
                available={true}
                hands={filterHands ? playerHands.filter(hand => hand.preflop.filter(action => action.nick === heroNick && action.type !== ACTION.FOLD).length > 0) : playerHands}
                selectedHandUuid={selectedPlayerHand?.uuid || ''}
                openDetails={openDetails}
                heroNick={heroNick}
                onSelectHand={setSelectedPlayerHand} />) : <Loading>LOADING...</Loading>
            }
        </HandListContainer>
    </>);
};

export const HandListContainer = styled.div<{marginTop?: number}>`
    max-width: 100vw;
    min-height: 100vh;
    overflow: scroll;
    margin-top: ${p => p.marginTop || 127}px;
`;

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  overflow: scroll;
  z-index: 1000;
`;

export const Loading = styled.div`
    font-size: 32px;
    width: 100%;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
    font-weight: 600;
    color: ${colors.whiteStrong};
`;

const IconWrapper = styled.div`
    padding: 16px;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    gap: 16px;
    color: ${colors.whiteStrong};
    font-size: 24px;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
`;