import React, { FC, useEffect, useState } from "react";

import { 
    IDayView, 
    getDaysInMonth 
} from "./Calendar.utils";
import { cloneDeep } from "lodash";
import styled from "styled-components";
import { colors } from "../../../styles/colors";
import ITag from "../../../types/Tag.types";
import { addZeroIfNeeded } from "./Calendar";

interface IMonthView extends IDayView {
    withExtraDays?: boolean;
    baseDate: Date;
    setCurrentDate: (date: Date) => void;
}

export const onlyUnique = (value: any, index: number, array: any[]) => {
    return array.indexOf(value) === index;
}

const weekDayNames = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const MonthView: FC<IMonthView> = ({
    date,
    withExtraDays,
    baseDate,
    items,
    setCurrentDate,
}) => {

    const [listOfDate, setListOfDates] = useState<Date[]>([]);
    const [finalList, setFinalList] = useState<any[]>([]);

    useEffect(() => {
        const days: Date[] = getDaysInMonth(date.getMonth(), date.getFullYear());

        while(days[0].getDay() !== 1) {
            const newDate = cloneDeep(days[0]);
            newDate.setDate(newDate.getDate() - 1);
            days.unshift(newDate);
        }

        while(days[days.length - 1].getDay() !== 0) {
            const newDate = cloneDeep(days[days.length - 1]);
            newDate.setDate(newDate.getDate() + 1);
            days.push(newDate);
        }

        setListOfDates(days);
    }, [date]);

    useEffect(() => {
        if (listOfDate.length > 0) {
            const innerFinalList: any[] = [];
            listOfDate.forEach(day => {
                const dayString = day.getFullYear() + "-" + addZeroIfNeeded(day.getMonth() + 1) + "-" + addZeroIfNeeded(day.getDate());
                const innerList = items.filter(item => item?.date?.startsWith(dayString))
                    .flatMap(item => item?.tags)
                    .filter(onlyUnique)
                    .filter(item => !!item?.name);
                innerFinalList.push({
                    date: dayString,
                    tags: innerList,
                });
            });
            setFinalList(innerFinalList);
        }
    }, [items, listOfDate]);

    const tagItems = (day: Date) => {
        return finalList?.filter((item: any) => item?.date?.startsWith(day.getFullYear() + "-" + addZeroIfNeeded(day.getMonth() + 1) + "-" + addZeroIfNeeded(day.getDate())))
            .flatMap(item => item?.tags?.filter((innerTag: ITag) => !!innerTag).map((tag: ITag) => {
                const counter = items.filter(item => item?.date?.startsWith(day.getFullYear() + "-" + addZeroIfNeeded(day.getMonth() + 1) + "-" + addZeroIfNeeded(day.getDate())))
                    .filter(item => item?.tags?.includes(tag))
                    .map(item => item?.nick)
                    .filter(onlyUnique)
                    .filter(item => !!item)
                    .length;
                return {
                    object: (<StyledSpan color={tag?.color} bgColor={tag?.bgColor}>
                        { `${tag?.name} (${counter})`}
                    </StyledSpan>),
                    counter: counter,
                }
            }));
    }

    return (<MainDiv withExtraDays={withExtraDays}>
        <MonthDiv withExtraDays={withExtraDays}>
        {
            weekDayNames.map(day => <DayDiv withOpacity={false} withExtraDays={withExtraDays} paddingBottom={true}>
                {day}
            </DayDiv>)
        }
        {
            listOfDate.map(day => <DayDiv 
                onClick={() => day.getMonth() !== date.getMonth() || setCurrentDate(day)}
                withOpacity={day.getMonth() !== date.getMonth()} 
                isToday={day.getDate() === baseDate.getDate() && day.getMonth() === baseDate.getMonth()}
                withExtraDays={withExtraDays}>
                <div>{day.getDate()}</div>
                <div>
                { 
                    tagItems(day).sort((item1: any, item2: any) => ((item2?.counter || 0) - (item1?.counter || 0)))
                        .map(item => item.object)
                }</div>
            </DayDiv>)
        }
        </MonthDiv>
    </MainDiv>);
};

const MainDiv = styled.div<{withExtraDays?: boolean}>`
    margin-top: ${p => p.withExtraDays && '32px'};
`

const MonthDiv = styled.div<{withExtraDays?: boolean}>`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 4px;
    font-size: ${p => p.withExtraDays ? 18 : 14}px;
    background-color: ${colors.white};
`;

const DayDiv = styled.div<{withOpacity: boolean, withExtraDays?: boolean, isToday?: boolean, paddingBottom?: boolean}>`
    box-sizing: border-box;
    padding: 8px 0;
    padding-bottom: ${p => p.paddingBottom ? 8 : 0}px;
    font-size: ${p => p.withExtraDays ? (p.paddingBottom ? 16: 18) : (p.paddingBottom ? 12 : 14)}px;
    background-color: ${colors.white};
    text-align: center;
    opacity: ${p => p.withOpacity && (p.withExtraDays ? '0.5' : '0')};
    border: ${p => p.isToday && '2px solid green'};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 4px;
    cursor: ${p => (!p.paddingBottom && !p.withOpacity) && 'pointer'};

    &:hover {
        opacity: ${p => (!p.paddingBottom && !p.withOpacity) && 0.8};
    }

    > div:nth-child(2) {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        overflow: scroll;
        height: 64px;
        gap: 2px;
    }
`;

const StyledSpan = styled.span<{color: string, bgColor: string}>`
    width: 100%;
    color: ${p => p.color};
    background-color: ${p => p.bgColor};
    display: block;
    height: 24px;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;