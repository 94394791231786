import React, { FC, useEffect, useState } from "react";

import { Header } from "../Header/Header";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import ITag from "../../types/Tag.types";
import { StyledButton } from "../commons/Button";
import { IoRemoveOutline } from "react-icons/io5";
import { 
    ErrorDiv,
    InfoDiv, 
} from "../HandDetails/Showdown";
import { Input } from "@chakra-ui/react";
import { StyledButtonsRow } from "../PlayerNotes/PlayerNotes";
import { SketchPicker } from 'react-color';
import { Link } from "./TagsPage";

export const TagsPlayerPage: FC = () => {

    const [tags, setTags] = useState<ITag[]>([]);
    const [newTagName, setNewTagName] = useState<string>('');
    const [color, setColor] = useState<string>('#000000');
    const [bgColor, setBgColor] = useState<string>('#ffffff');
    const [selectedTag, setSelectedTag] = useState<ITag | null>(null);
    const [isError, setIsError] = useState<boolean>(false);
    const [isDeleted, setIsDeleted] = useState<boolean>(false);
    
    const user = JSON.parse(sessionStorage?.getItem('user') || '{}');
    const userId = user?.uuid;
    const groupId = user?.groupId;
    const isAdmin = user?.admin;

    useEffect(() => {
        fetch(`http://146.59.44.6:8080/tagPlayer${!!groupId ? '?groupId=' + groupId : ''}`,{ 
            method: "GET",
            headers: { 'Content-Type': 'application/json'}
        }).then(res => res.ok ? res.json() : [])
        .then(json => setTags(json));
    }, []);
    if (!userId) {
        window.location.href = '/login';
    }

    return(<>
        <Header itemSelected={3} />
        <TagsContainer isAdmin={isAdmin}>
        { isAdmin && (<AddTagSection>
                <ColorSection>
                    <div>TAG PLAYER NAME</div>
                    <Input 
                        padding={4}
                        width={400}
                        value={!!selectedTag ? selectedTag.name : newTagName}
                        onChange={e => {
                            if (!!selectedTag) {
                                setSelectedTag((prevTag: ITag | null) => {
                                    return !!prevTag ? {
                                        ...prevTag,
                                        name: e.target.value
                                    } : null;
                                })
                            } else {
                                setNewTagName(e.target.value);
                            }
                    }}/>
                </ColorSection>
                <Row>
                    <ColorSection>
                        <div>FONT COLOR</div>
                        <SketchPicker 
                            color={ !selectedTag ? color : selectedTag.color }
                            onChangeComplete={(color, event) => {
                                if (!!selectedTag) {
                                    setSelectedTag((prevTag: ITag | null) => {
                                        return !!prevTag ? {
                                            ...prevTag,
                                            color: color.hex
                                        } : null
                                    })
                                } else {
                                    setColor(color.hex);
                                }
                            }} />
                    </ColorSection>
                    <ColorSection>
                        <div>BACKGROUND COLOR</div>
                        <SketchPicker 
                            color={ !selectedTag ? bgColor : selectedTag.bgColor }
                            onChangeComplete={(bgColor, event) => {
                                if (!!selectedTag) {
                                    setSelectedTag((prevTag: ITag | null) => {
                                        return !!prevTag ? {
                                            ...prevTag,
                                            bgColor: bgColor.hex
                                        } : null
                                    })
                                } else {
                                    setBgColor(bgColor.hex);
                                }
                            }} />
                    </ColorSection>
                </Row>
                <StyledButtonsRow gap={16}>
                    <StyledButton 
                        width={100}
                        bgColor={colors.buttons.download} 
                        disabled={!selectedTag && newTagName.length === 0} 
                        isActive={!!selectedTag || newTagName.length !== 0}
                        onClick={() => {
                            if (!!selectedTag) {
                                fetch(`http://146.59.44.6:8080/tagPlayer${!!groupId ? '?groupId=' + groupId : ''}`, { 
                                    method: "POST",
                                    mode: 'cors',
                                    headers: { 'Content-Type': 'application/json'},
                                    body: JSON.stringify(selectedTag)
                                }).then(res => {
                                    if (res.ok) {
                                        return res.json()
                                    } else {
                                        return null
                                    }
                                }).then(json => {
                                    if (!json) { 
                                        setIsError(true);
                                    } else {
                                        const actualTag = tags.find((tag: ITag) => tag.uuid === selectedTag.uuid);
                                        if (!!actualTag) {
                                            actualTag.name = json?.name;
                                            actualTag.color = json?.color;
                                            actualTag.bgColor = json?.bgColor;
                                        }
                                        setSelectedTag(null);
                                        setNewTagName('');
                                    }
                                })
                                .catch(e => setIsError(true));
                            } else if (newTagName.length > 0) {
                                fetch(`http://146.59.44.6:8080/tagPlayer${!!groupId ? '?groupId=' + groupId : ''}`, { 
                                    method: "POST",
                                    mode: 'cors',
                                    headers: { 'Content-Type': 'application/json'},
                                    body: JSON.stringify({
                                        name: newTagName,
                                        color: color,
                                        bgColor: bgColor
                                    })
                                }).then(res => {
                                    if (res.ok) {
                                        return res.json()
                                    } else {
                                        return null
                                    }
                                }).then(json => !json ? setIsError(true) : tags.push(json) && setNewTagName(''))
                                .catch(e => setIsError(true));
                        }}}>{!!selectedTag ? 'SAVE' : 'ADD' }</StyledButton>
                    <StyledButton 
                        width={100}
                        bgColor={colors.buttons.delete} 
                        disabled={!selectedTag && newTagName.length === 0} 
                        isActive={!!selectedTag || newTagName.length !== 0}
                        onClick={() => {
                            setSelectedTag(null);
                            setNewTagName('');
                            setColor('#000000');
                            setBgColor('#ffffff');
                        }}>CLEAR</StyledButton>
                </StyledButtonsRow>
            </AddTagSection>)
            }
            <div>ALL TAGS</div>
            { isError && (<ErrorDiv>ERROR DURING PROCESSING TAG</ErrorDiv>) }
            { isDeleted && (<InfoDiv>TAG DELETED</InfoDiv>) }
            <List>
            {
                tags?.map((tag: ITag, id: number) => (<TagItem color={tag.color} bgColor={tag.bgColor} isSelected={selectedTag?.uuid === tag.uuid}>
                    <div>{ id + 1 }</div>
                    <Link fullWidth={!isAdmin} onClick={() => {
                        window.location.href = `/players/tag/${tag.uuid}`
                    }}>{ tag.name }</Link>
                    { isAdmin && (<>
                        <StyledButton 
                            isActive={true}
                            height={36}
                            onClick={() => selectedTag?.uuid === tag.uuid ? setSelectedTag(null) : setSelectedTag(tag)}>EDIT</StyledButton>
                        <StyledButton 
                            isActive={true}
                            bgColor={colors.buttons.delete}
                            height={36}
                            onClick={() => {
                                setIsDeleted(false);
                                setIsError(false);
                                fetch(`http://146.59.44.6:8080/tagPlayer${!!groupId ? '?groupId=' + groupId : ''}`,{ 
                                    method: "DELETE",
                                    mode: 'cors',
                                    headers: { 'Content-Type': 'application/json'},
                                    body: JSON.stringify(tag)
                                }).then(res => {
                                    if (res.ok) {
                                        return res.json()
                                    } else {
                                        return null
                                    }
                                }).then(json => {
                                    if (!json) {
                                        setIsError(true);
                                    } else {
                                        setIsDeleted(true);
                                        setTags((prevTags: ITag[]) => prevTags.filter((prevTag: ITag) => prevTag.uuid !== json?.uuid))
                                    }
                                })
                                .catch(e => setIsError(true));
                            }}>
                            <IoRemoveOutline />
                        </StyledButton>
                    </>)
                }
                </TagItem>))
            }
            </List>
        </TagsContainer>
    </>);
};

const TagsContainer = styled.div<{isAdmin?: boolean}>`
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: center;
    color: ${colors.white};
    margin: 0 auto;
    margin-top: 127px;
    padding: 32px 0;

    > div:nth-child(${p => p.isAdmin ? 2 : 1}) {
        font-size: 21px;
        line-height: 32px;
        font-weight: 600;
    }
`;

const List = styled.div`
    border-top: 2px solid ${colors.white};
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    > div:last-child {
        border-bottom: 2px solid ${colors.white};
    }
`;

const TagItem = styled.div<{color: string, bgColor: string, isSelected: boolean}>`
    background-color: ${p => p.isSelected && colors.highlightedNote};
    border-bottom: 2px solid ${colors.border};
    display: flex;
    gap: 16px;
    align-items: center;
    padding: 8px 4px;

    > div:nth-child(2) {
        text-align: center;
        min-width: 300px;
        border-radius: 8px;
        padding: 8px 4px;
        color: ${p => p.color};
        background-color: ${p => p.bgColor};
    }

    > div:first-child {
        width: 40px;
    }
`;

const AddTagSection = styled.div`
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const ColorSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
    gap: 8px
`;

const Row = styled.div`
    display: flex;
    gap: 32px;
`;