import { cloneDeep } from "lodash";
import IAction, { ACTION } from "../types/Action.types";
import IHand from "../types/Hand.types";
import IPlayer from "../types/Player.types";
import ICard from "../types/Card.types";
import JSZip from "jszip";
import { saveAs } from 'file-saver';

const HandPokerSolver = require('pokersolver').Hand;

const getLastAmount = (action: ACTION, value: number, prevValue?: number) => {
    if (action.valueOf() === ACTION.ANTE.valueOf()) {
        return value;
    } else if (action.valueOf() === ACTION.CALL.valueOf()) {
        return value;
    } else if (action.valueOf() === ACTION.FOLD.valueOf()) {
        return 0;
    } else if (action.valueOf() === ACTION.CHECK.valueOf()) {
        return 0;
    } else if (action.valueOf() === ACTION.BET.valueOf()) {
        return value - (prevValue || 0);
    } else if (action.valueOf() === ACTION.ALL_IN.valueOf()) {
        return value;
    } else if (action.valueOf() === ACTION.RAISE.valueOf()) {
        return value - (prevValue || 0);
    }

    return 0;
}

const mapAction = (action: ACTION, value: number, prevValue?: number) => {
    if (action.valueOf() === ACTION.ANTE.valueOf()) {
        return `puts ante $${value}`;
    } else if (action.valueOf() === ACTION.CALL.valueOf()) {
        return `calls $${value}`;
    } else if (action.valueOf() === ACTION.FOLD.valueOf()) {
        return `folds`;
    } else if (action.valueOf() === ACTION.CHECK.valueOf()) {
        return `checks`;
    } else if (action.valueOf() === ACTION.BET.valueOf()) {
        if (!!prevValue && prevValue > 0 && prevValue < value) {
            return `raises $${prevValue} to $${value}`;
        }
        return `bets $${value}`;
    } else if (action.valueOf() === ACTION.ALL_IN.valueOf()) {
        return `bets $${value} and is all-in`;
    } else if (action.valueOf() === ACTION.RAISE.valueOf()) {
        return `raises $${prevValue} to $${value}`;
    }
}

interface IProps {
    name: string;
    content: string;
}

export const uploadPSHandHistoryAll = async (playerHands: IHand[]) => {
    const fileContents: IProps[] = [];
    for (let i = 0; i < playerHands.length; i++) {
        const innerBoard: ICard[] = [];
        playerHands[i].board.forEach(card => {
            if (!innerBoard.find((innerCard) => innerCard.color === card.color && innerCard.figure === card.figure)) {
                innerBoard.push(card);
            }
        });
        uploadPSHandHistory(playerHands[i], innerBoard, fileContents);
    }
    const zip = new JSZip();
    fileContents.forEach(file => {
        zip.file(file.name, file.content);
    });
    const content = await zip.generateAsync({ type: "blob" });
    saveAs(content, "hand_history.zip");
}

export const uploadPSHandHistory = (hand: IHand, innerBoard: ICard[], fileContents?: IProps[]) => {
    const dateObject = new Date(hand.date);
    hand.board = innerBoard;
    let playersLeft = cloneDeep(hand.players);
    let lastActionValue = 0;
    const buttonIdx = hand.players.length === 2 ? 1 : (hand.players.length - 2);

    let text = `PokerStars Hand #${hand.title.replaceAll('-', '')}:`;
    text +=  `Hold'em No Limit (${hand.sb}/${hand.bb})`;
    text += ` - ${dateObject.toLocaleString("en-CA", {
        timeZone: "Asia/Seoul",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
    }).replace(/-/g, "/").replace(',', '')} KST `
    text += `[${dateObject.toLocaleString("en-CA", {
        timeZone: "America/New_York",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
    }).replace(/-/g, "/").replace(',', '')} ET]\n`;

    text += `Table 'Example' ${hand.players.length}-max Seat #${buttonIdx} is the button\n`;

    const oldPlayers = cloneDeep(hand.players);
    hand.players = [];
    hand.preflop.forEach(action => {
        const actual = oldPlayers.find(player => player.nick === action.nick);
        if (actual && !hand.players.find(player => player.nick === action.nick)) {
            hand.players.push(actual);
        }
    })

    hand.players.forEach((player: IPlayer, idx: number) => {
        text += `Seat ${idx + 1}: ${player.nick} ($${player.stack} in chips)\n`;
    });

    hand.players.forEach((player: IPlayer, idx: number) => {
        text += `$${player.nick} posts ante $${hand.bb}\n`;
    });

    hand.blind.forEach((action: IAction, idx: number) => {
        if (action.type.valueOf().includes('Big Blind')) {
            text += `${action.nick} posts big blind $${hand.bb}\n`;
        } else if (action.type.valueOf().includes('Small Blind')) {
            text += `${action.nick} posts small blind $${hand.sb}\n`;
        }
    });

    const flop = cloneDeep(hand.board).splice(0, 3);
    const turn = cloneDeep(hand.board).splice(0, 4);

    let prevValue = 0;
    if (hand.preflop.length > 0) {
        hand.preflop = hand.preflop.sort((action1, action2) => (action1?.actionId || 0) - (action2?.actionId || 0))
        text += `*** HOLE CARDS ***\n`;
        const allIns = cloneDeep(hand.preflop).filter(action => action.type.valueOf() === ACTION.ALL_IN.valueOf());
        if (allIns.length === 2 && (allIns[0].amount || 0) < (allIns[1].amount || 0)) {
            hand.preflop.forEach(action => {
                if (action.actionId === allIns[1].actionId) {
                    action.type = ACTION.CALL;
                }
            });
        }
        hand.preflop.forEach(action => {
            text += `${action.nick}: ${mapAction(action.type, (action.amount || 0), prevValue)}\n`;
            const indexOfPlayer = hand.players.findIndex(player => player.nick === action.nick);
            const withBet = action.type.valueOf() === ACTION.BET.valueOf() || action.type.valueOf() === ACTION.RAISE.valueOf() || action.type.valueOf() === ACTION.ALL_IN.valueOf();
            hand.players[indexOfPlayer].lastAction = {
                ...action,
                when: 'before Flop',
                withBet: (hand.players[indexOfPlayer].lastAction?.withBet && hand.players[indexOfPlayer].lastAction?.when === 'before Flop') || action.type.valueOf() !== ACTION.FOLD.valueOf()
            };

            if (action.type.valueOf() === ACTION.FOLD.valueOf()) {
                playersLeft = playersLeft.filter(player => player.nick !== action.nick);
            } else {
                lastActionValue = getLastAmount(action.type, (action.amount || 0), prevValue);
            }

            if (withBet) {
                prevValue = (action.amount || 0);
            }
        })
    }

    prevValue = 0;
    if (hand.flop.length > 0 || hand.board.length > 0) {
        hand.flop = hand.flop.sort((action1, action2) => (action1?.actionId || 0) - (action2?.actionId || 0))
        text += `*** FLOP *** [${flop.map(card => card.figure + card.color).reduce((s1, s2) => s1 + ' ' + s2)}]\n`;
        if (hand.flop.length > 0) {
            const allIns = cloneDeep(hand.flop).filter(action => action.type.valueOf() === ACTION.ALL_IN.valueOf());
            if (allIns.length === 2 && (allIns[0].amount || 0) < (allIns[1].amount || 0)) {
                hand.flop.forEach(action => {
                    if (action.actionId === allIns[1].actionId) {
                        action.type = ACTION.CALL;
                    }
                });
            }
            hand.flop.forEach(action => {
                text += `${action.nick}: ${mapAction(action.type, (action.amount || 0), prevValue)}\n`;
                const indexOfPlayer = hand.players.findIndex(player => player.nick === action.nick);
                const withBet = action.type.valueOf() === ACTION.BET.valueOf() || action.type.valueOf() === ACTION.RAISE.valueOf() || action.type.valueOf() === ACTION.ALL_IN.valueOf();
                hand.players[indexOfPlayer].lastAction = {
                    ...action,
                    when: 'on the Flop',
                    withBet: true,
                };

                if (action.type.valueOf() === ACTION.FOLD.valueOf()) {
                    playersLeft = playersLeft.filter(player => player.nick !== action.nick);
                } else {
                    lastActionValue = getLastAmount(action.type, (action.amount || 0), prevValue);
                }

                if (withBet) {
                    prevValue = (action.amount || 0);
                }
            });
        }
    }

    prevValue = 0;
    if (hand.turn.length > 0 || hand.board.length > 3) {
        hand.turn = hand.turn.sort((action1, action2) => (action1?.actionId || 0) - (action2?.actionId || 0))
        text += `*** TURN *** [${flop.map(card => card.figure + card.color).reduce((s1, s2) => s1 + ' ' + s2)}] [${cloneDeep(hand.board).splice(3, 1).map(card => card.figure + card.color)}]\n`;
        if (hand.turn.length > 0) {
            const allIns = cloneDeep(hand.turn).filter(action => action.type.valueOf() === ACTION.ALL_IN.valueOf());
            if (allIns.length === 2 && (allIns[0].amount || 0) < (allIns[1].amount || 0)) {
                hand.turn.forEach(action => {
                    if (action.actionId === allIns[1].actionId) {
                        action.type = ACTION.CALL;
                    }
                });
            }
            hand.turn.forEach(action => {
                text += `${action.nick}: ${mapAction(action.type, (action.amount || 0), prevValue)}\n`;
                const indexOfPlayer = hand.players.findIndex(player => player.nick === action.nick);
                const withBet = action.type.valueOf() === ACTION.BET.valueOf() || action.type.valueOf() === ACTION.RAISE.valueOf() || action.type.valueOf() === ACTION.ALL_IN.valueOf();
                hand.players[indexOfPlayer].lastAction = {
                    ...action,
                    when: 'on the Turn',
                    withBet: true,
                };

                if (action.type.valueOf() === ACTION.FOLD.valueOf()) {
                    playersLeft = playersLeft.filter(player => player.nick !== action.nick);
                } else {
                    lastActionValue = getLastAmount(action.type, (action.amount || 0), prevValue);
                }

                if (withBet) {
                    prevValue = (action.amount || 0);
                }
            });
        }
    }

    prevValue = 0;
    if (hand.river.length > 0 || hand.board.length > 4) {
        hand.river = hand.river.sort((action1, action2) => (action1?.actionId || 0) - (action2?.actionId || 0))
        text += `*** RIVER *** [${turn.map(card => card.figure + card.color).reduce((s1, s2) => s1 + ' ' + s2)}] [${cloneDeep(hand.board).splice(4, 1).map(card => card.figure + card.color)}]\n`;
        if (hand.river.length > 0) {
            const allIns = cloneDeep(hand.river).filter(action => action.type.valueOf() === ACTION.ALL_IN.valueOf());
            if (allIns.length === 2 && (allIns[0].amount || 0) < (allIns[1].amount || 0)) {
                hand.river.forEach(action => {
                    if (action.actionId === allIns[1].actionId) {
                        action.type = ACTION.CALL;
                    }
                });
            }

            hand.river.forEach(action => {
                text += `${action.nick}: ${mapAction(action.type, (action.amount || 0), prevValue)}\n`;
                const indexOfPlayer = hand.players.findIndex(player => player.nick === action.nick);
                const withBet = action.type.valueOf() === ACTION.BET.valueOf() || action.type.valueOf() === ACTION.RAISE.valueOf() || action.type.valueOf() === ACTION.ALL_IN.valueOf();
                hand.players[indexOfPlayer].lastAction = {
                    ...action,
                    when: 'on the River',
                    withBet: true,
                };

                if (action.type.valueOf() === ACTION.FOLD.valueOf()) {
                    playersLeft = playersLeft.filter(player => player.nick !== action.nick);
                } else {
                    lastActionValue = getLastAmount(action.type, (action.amount || 0), prevValue);
                }

                if (withBet) {
                    prevValue = (action.amount || 0);
                }
            });
        }
    }

    let totalPot = hand.pot[hand.pot.length - 1];

    if (playersLeft.length === 1) {
        totalPot -= lastActionValue;
    }

    const winners = cloneDeep(playersLeft).filter(player => (player.balance || 0) >= (-1 * Math.round(5.3 * totalPot / 100)) / playersLeft.length);

    if (playersLeft.length > 1) {
        text += '*** SHOW DOWN ***\n';
        playersLeft.forEach(player => {
            let cards = '';
            player.cards.forEach(card => {
                cards += `${card.figure}${card.color} `;
            });
            cards = cards.slice(0, cards.length - 1);

            const allCards: string[] = [];
            hand.board.forEach(card => allCards.push(card.figure + card.color));
            const actualAllCards: string[] = allCards.concat([]);
            player.cards.forEach((card: ICard) => actualAllCards.push(card.figure + card.color));
            const handSolver = actualAllCards.length > 0 && HandPokerSolver.solve(actualAllCards);
            const desc = mapHandResult(handSolver.descr);
            text += `${player.nick}: shows [${cards}] (${desc})\n`;
            player.additionalInfo = `showed [${cards}] and ${(player.balance || 0) > ((-1 * Math.round(5.3 * totalPot / 100)) / playersLeft.length) ? `won ($${(totalPot - Math.round(5.3 * totalPot / 100)) / winners.length})` : 'lost'} with ${desc}`;
        });
    }
    
    if (playersLeft.length === 1) {
        text += `Uncalled bet ($${lastActionValue}) returned to ${playersLeft[0].nick}\n`;
        playersLeft[0].additionalInfo = `collected ($${(totalPot - Math.round(5.3 * totalPot / 100)) / winners.length})`;
    }

    winners.forEach(player => {
        text += `${player.nick} collected $${(totalPot - Math.round(5.3 * totalPot / 100)) / winners.length} from pot\n`;
    });

    text += `*** SUMMARY ***\n`;
    text += `Total pot $${totalPot} | Rake $${Math.round(5.3 * totalPot / 100)}\n`;
    if (hand.board.length > 0) {
        text += `Board [${cloneDeep(hand.board).map(card => card.figure + card.color).reduce((s1, s2) => s1 + ' ' + s2)}]\n`;
    }
    hand.players.forEach((player: IPlayer, idx: number) => {
        text += `Seat ${idx + 1}: ${player.nick}${getAdditonalData(idx, hand.players.length)} ${mapLastAction(player.lastAction, playersLeft, hand.blind)}\n`;
        if (player.lastAction?.type.valueOf() === ACTION.FOLD.valueOf() || playersLeft.length === 1) {
            text += `Seat ${idx + 1}: ${player.nick} mucked [${player.cards.map(card => card.figure + card.color).reduce((s1, s2) => s1 + ' ' + s2)}]\n`;
        }
    })

    if (!fileContents) {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', hand.title);
    
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    } else {
        fileContents.push({
            content: text,
            name: hand.title + '.txt'
        })
    }
}

const mapLastAction = (lastAction: IAction | undefined, playersLeft: IPlayer[], blindActions: IAction[]) => {
    if (lastAction?.type.valueOf() === ACTION.FOLD.valueOf()) {
        return `folded ${lastAction.when}${lastAction.withBet || (blindActions.find(action => action.nick === lastAction.nick && action.type.valueOf().toLowerCase().includes('blind'))) ? '' : ' (didn\'t bet)'}`
    } else {
        const actualPlayer = playersLeft.find(player => player.nick === lastAction?.nick);
        if (actualPlayer) {
            return actualPlayer.additionalInfo;
        }
    }
    return ''
}

const singleMap: Record<string, string> = {
    'a': 'Ace',
    'k': 'King',
    'q': 'Queen',
    'j': 'Jack',
    't': 'Ten',
    '9': 'Nine',
    '8': 'Eight',
    '7': 'Seven',
    '6': 'Six',
    '5': 'Five',
    '4': 'Four',
    '3': 'Three',
    '2': 'Two',
    'order': 'Ace,King,Queen,Jack,Ten,Nine,Eight,Seven,Six,Five,Four,Three,Two,Ace'
};

const plurarMap: Record<string, string> = {
    'a': 'Aces',
    'k': 'Kings',
    'q': 'Queens',
    'j': 'Jacks',
    't': 'Tens',
    '9': 'Nines',
    '8': 'Eights',
    '7': 'Sevens',
    '6': 'Sixes',
    '5': 'Fives',
    '4': 'Fours',
    '3': 'Threes',
    '2': 'Twos'
};

const getAdditonalData = (idx: number, size: number) => {
    if (idx + 3 === size) {
        return ' (button)'
    } else if (idx + 2 === size) {
        return ' (small blind)'
    } else if (idx + 1 === size) {
        return ' (big blind)'
    }

    return ''
}

const mapHandResult = (description: string) => {
    const results = description.toLowerCase().split(',');
    if (results.length > 1 && results[0].toLowerCase().includes('two pair')) {
        const items: string[] = results[1].split(' & ');
        return `two pair, ${plurarMap[items[0].replace('\'s', '').trim()]} and ${plurarMap[items[1].replace('\'s', '').trim()]}`;
    } else if (results.length > 1 && results[0].toLowerCase().includes('pair')) {
        const items: string[] = results[1].split('\'s');
        return `a pair, ${plurarMap[items[0].trim()]}`;
    } else if (results.length > 1 && description.toLowerCase().includes('three of a kind')) {
        const items = results[1].toLowerCase().trim();
        return `three of a kind, ${plurarMap[items[0].replace('s', '')]}`;
    } else if (results.length > 1 && description.toLowerCase().includes('four of a kind')) {
        const items = results[1].toLowerCase().trim();
        return `four of a kind, ${plurarMap[items[0].replace('s', '')]}`;
    } else if (results.length > 1 && results[0] === 'straight') {
        const items = results[1].toLowerCase().trim();
        const finalItem = singleMap[items[0].replace('s', '')];
        const orderItems = singleMap['order'].split(',');
        const finalItem2 = orderItems.indexOf(finalItem) + 4;
        return `a straight, ${finalItem} to ${orderItems[finalItem2]}`;
    } else if (results.length > 1 && description.toLowerCase().includes('full house')) {
        const items = results[1].toLowerCase().split(' over ');
        return `a full house, ${plurarMap[items[0].replace('\'s', '').trim()]} full of ${plurarMap[items[1].replace('\'s', '').trim()]}`;
    } else if (results.length > 1 && results[0].toLowerCase() === 'flush') {
        const items = results[1].trim().toLowerCase().split(' ');
        return `a flush, ${singleMap[items[0].replace('s', '').trim()]} high`;
    } else if (results.length > 1 && results[0].toLowerCase() === 'straight flush') {
        const items = results[1].trim().toLowerCase().split(' ');
        const finalItem = singleMap[items[0].replace('s', '').trim()];
        const orderItems = singleMap['order'].split(',');
        const finalItem2 = orderItems.indexOf(finalItem) + 4;
        return `a straight flush, ${finalItem} to ${orderItems[finalItem2]}`;
    } else if (description.toLowerCase().includes('royal flush')) {
        return `a royal flush`;
    } else if (description.toLowerCase().includes('high')) {
        const items = description.toLowerCase().split(' ');
        return `high card, ${singleMap[items[0].replace('s', '')]}`;
    }

    return '(high card Ace)'
}