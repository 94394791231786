import React, { 
    FC, 
    useEffect, 
    useState, 
} from 'react';

import { MultiSelect } from "react-multi-select-component";
import { Wrapper } from './Filters.styles';
import { 
    IProps, 
    ISelect, 
} from './Filters.types';

export const Filters: FC<IProps> = ({
    selectedStakes,
    setSelectedStakes,
    setSearchTrigger,
}) => {

    const [stakes, setStakes] = useState<number[][]>(selectedStakes);
    const [visualStakes, setVisualStakes] = useState<ISelect[]>([]);
    const [selectedVisualStakes, setSelectedVisualStakes] = useState<ISelect[]>([]);

    useEffect(() => {
        const newStakes = stakes.map((stake: number[]) => {
        return { 
            label: `${(Math.round(stake[0] * 10 / 1000) / 10) }k`,
            value: stake
        }});
        setVisualStakes(newStakes);
    }, [stakes]);

    useEffect(() => {
        setSelectedStakes(selectedVisualStakes.map(stake => stake.value))
    }, [selectedVisualStakes])

    useEffect(() => {
        fetch(`http://146.59.44.6:8080/hand/blinds`,{ 
            method: "GET",
            headers: { 'Content-Type': 'application/json'}
        }).then(res => res.ok ? res.json() : [])
        .then(json => setStakes(json));
    }, []);

    return (<Wrapper>
        <div>
            <label>Stakes</label>
            <MultiSelect
                options={visualStakes}
                value={selectedVisualStakes}
                onChange={setSelectedVisualStakes}
                labelledBy="Stakes"
            />
        </div>
        <div className='clear'>
            <button onClick={() => {
                setSelectedVisualStakes([]);
            }}>CLEAR</button>
        </div>
        <div className='search'>
            <button onClick={() => setSearchTrigger((prevState: boolean) => !prevState)}>SEARCH</button>
        </div>
    </Wrapper>);
};