import React, { FC } from "react";

import IAction, { ACTION } from "../../types/Action.types";
import { styled } from "styled-components";
import { colors } from "../../styles/colors";
import IPlayer from "../../types/Player.types";

interface IActionComp {
    action: IAction,
    heroNick: string,
    players: IPlayer[],
    colorIndexes: number[]
}

export const Action: FC<IActionComp> = ({ 
    action,
    heroNick,
    players,
    colorIndexes
}) => {

    const isHero = action.nick === heroNick;

    let amount = (action.amountInBB !== undefined) ? action.amountInBB : 0;

    return (<Element 
        isHero={isHero} 
        idx={players.indexOf(players.filter(player => player.nick === action.nick)?.[0])}
        colorIndexes={colorIndexes}>
        { action.type.valueOf() === ACTION.CHECK.valueOf() ? 'X' : action.type[0] }
        { amount > 0 && amount }
    </Element>);
};

const Element = styled.div<{isHero: boolean, idx: number, colorIndexes: number[]}>`

    color: ${p=> colors.player[p.colorIndexes[p.idx]]};

    text-decoration: ${p => p.isHero && 'underline'};
`;