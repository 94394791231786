import React, { 
    FC, 
    useEffect, 
    useState, 
} from "react";

import { Header } from "../../Header/Header";
import styled from "styled-components";
import { IGroup, IUser } from "../../../types/IGroup.types";
import { Group } from "./Group";
import { StyledButton } from "../../commons/Button";
import { colors } from "../../../styles/colors";

export const Groups: FC = () => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [groups, setGroups] = useState<IGroup[]>([]);
    const [users, setUsers] = useState<IUser[]>([]);

    const user = JSON.parse(sessionStorage?.getItem('user') || '{}');
    const userId = user?.uuid;
    const groupId = user?.groupId;
    const isAdmin = user?.admin;

    if (!userId) {
        window.location.href = '/login';
    } else if (!isAdmin) {
        window.location.href = '/';
    }

    const removeGroup = (groupId: string) => {
        /* eslint-disable */
        if (confirm('Are you sure you want to delete this group?')) {
            fetch(`http://146.59.44.6:8080/group/delete${!!groupId ? '?groupId=' + groupId : ''}`, { 
                method: "DELETE",
                mode: 'cors',
                headers: { 'Content-Type': 'application/json'},
                body: JSON.stringify(groups?.find(group => group.uuid === groupId))
            }).then(res => res.text())
            .then(text => JSON.parse(text))
            .then(json => {
                setGroups((prevState: IGroup[]) => {
                    const newState: IGroup[] = [];
                    prevState.forEach(item => {
                        if (item.uuid !== json.uuid) {
                            newState.push(item);
                        }
                    });
                    return newState;
                });
            });
        }
        /* eslint-enable */
    };

    const saveGroup = (group: IGroup) => {
        fetch(`http://146.59.44.6:8080/group/save${!!groupId ? '?groupId=' + groupId : ''}`, { 
            method: !!group?.uuid ? "PUT" : "POST",
            mode: 'cors',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify(group)
        }).then(res => res.text())
        .then(text => JSON.parse(text))
        .then(json => {
            setGroups((prevState: IGroup[]) => {
                const newState = [];
                for (let i = 0; i < prevState.length; i++) {
                    if (!!prevState[i].uuid) {
                        if (prevState[i].uuid === group.uuid) {
                            newState.push(group);
                        } else {
                            newState.push(prevState[i]);
                        }
                    }
                }
                if (!group?.uuid) {
                    newState.push(json);
                }
                return [...newState];
            });
        });        
    }

    useEffect(() => {
        setIsLoading(true);

        fetch(`http://146.59.44.6:8080/group/all${!!groupId ? '?groupId=' + groupId : ''}`,{ 
            method: "GET",
            headers: { 'Content-Type': 'application/json'}
        }).then(res => res.text())
        .then(text => JSON.parse(text))
        .then(json => {
            setGroups(json);
            setIsLoading(false);

            fetch(`http://146.59.44.6:8080/user/all${!!groupId ? '?groupId=' + groupId : ''}`,{ 
                method: "GET",
                headers: { 'Content-Type': 'application/json'}
            }).then(res => res.text())
            .then(text => JSON.parse(text))
            .then(json => {
                setUsers(json);
                setIsLoading(false);
            });
        });
    }, []);

    useEffect(() => {
        setGroups((prevState: IGroup[]) => {
            const finalState: IGroup[] = [];
            prevState.forEach(group => {
                group.users = [];
                users.filter(user => user.groupId === group.uuid).forEach(user => group.users.push(user));
                finalState.push(group);
            });
            return finalState;
        });
    }, [users]);

    const isNewAvailable = groups.filter(group => !group?.uuid).length === 0;

    return (<>
        <Header itemSelected={9} />
        <Div disabled={!isNewAvailable}>
            <StyledButton disabled={!isNewAvailable} bgColor={colors.buttons.download} opacity={1} cursor={'pointer'} width={200} onClick={() => {
                isNewAvailable && setGroups((prevState: IGroup[]) => {
                    const newState = [...prevState];
                    newState.push({
                        name: '',
                        uuid: '',
                        users: []
                    });
                    return newState;
                })
            }}>ADD GROUP</StyledButton>
            <MainSection>
            {
                groups.map((group: IGroup) => (<Group 
                    name={group.name} 
                    users={group.users} 
                    uuid={group.uuid} 
                    allUsers={users.filter(user => groups.filter(group => group.uuid === user.groupId).length === 0)}
                    removeGroup={removeGroup}
                    saveGroup={saveGroup}
                    setUsers={setUsers} />))
            }
            </MainSection>
        </Div>
    </>)
};

const Div = styled.div<{disabled?: boolean}>`
    opacity: 1;
    margin-top: 127px;
    padding-top: 16px;
    max-width: 1328px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${p => p.disabled && `> button { 
        cursor: default !important;
        opacity: 1 !important;
    }`}
`;

const MainSection = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    padding: 16px;
`;