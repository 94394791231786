import React, { 
    FC, 
    useEffect, 
    useState, 
} from "react";
import { Header } from "../Header/Header";
import styled from "styled-components";
import { 
    Button, 
    Input, 
} from "@chakra-ui/react";
import { colors } from "../../styles/colors";

export const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

export const Register: FC = () => {

    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [password2, setPassword2] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [errors, setErrors] = useState<string[]>([]);
    const [info, setInfo] = useState<string>('');

    useEffect(() => {
        validate();
    }, [username, password, password2, email]);

    useEffect(() => {
        !!sessionStorage.getItem('user') && (window.location.href = '/');
    }, []);

    const validate = () => {
        setErrors((prevErrors: string[]) => {
            prevErrors = [];
            if (!username) {
                prevErrors.push('Username not set');
            }
            if (!password) {
                prevErrors.push('Password not set');
            }
            if (!password2) {
                prevErrors.push('Password not repeated');
            }
            if (!email) {
                prevErrors.push('Email not set');
            }
            if (!!password && !!password2 && password !== password2) {
                prevErrors.push('Passwords don\'t match');
            }
            return prevErrors;
        });
    }

    return (<>
        <Header itemSelected={5} />
        <LoginContainer>
            <div>
                <span>USERNAME</span>
                <Input width={300} fontSize={16} padding={8} borderRadius={8} onChange={event => {
                    setUsername(event.target.value);
                }}/>
            </div>
            <div>
                <span>PASSWORD</span>
                <Input width={300} fontSize={16} padding={8} type={'password'} borderRadius={8} onChange={event => {
                    setPassword(event.target.value);
                }}/>
            </div>
            <div>
                <span>REPEAT PASSWORD</span>
                <Input width={300} fontSize={16} padding={8} type={'password'} borderRadius={8} onChange={event => {
                    setPassword2(event.target.value);
                }}/>
            </div>
            <div>
                <span>EMAIL</span>
                <Input width={300} fontSize={16} padding={8} type={'email'} borderRadius={8} onChange={event => {
                    setEmail(event.target.value);
                }}/>
            </div>
            <div>
                <Button isDisabled={errors.length > 0} width={150} fontSize={21} padding={8} backgroundColor={colors.buttons.download} color={colors.white} borderRadius={8} onClick={() => {
                    validate();
                    if (errors.length === 0) {
                        fetch(`http://146.59.44.6:8080/user/register`,{ 
                            method: "POST",
                            mode: 'cors',
                            headers: { 'Content-Type': 'application/json'},
                            body: JSON.stringify({
                                nick: username,
                                password: password,
                                email: email
                            })
                        }).then(async res => {
                            if(res.ok) {
                                setInfo('Registration process finished successfully.');
                                await delay(2000);
                                window.location.href = '/';
                            } else {
                                setErrors(['Registration process failed. Please, try again later.']);
                            }
                        });
                    }
                }}>REGISTER</Button>
            </div>
        </LoginContainer>
        <ErrorDiv withError={errors.length > 0} withSuccess={!!info}>{errors.length > 0 ? errors.map((error: string) => (<div>{error}</div>)) : <div>{ info }</div>}</ErrorDiv>
    </>);
};

const LoginContainer = styled.div`
    padding: 32px;
    border-radius: 16px;
    border: 4px solid ${colors.white};
    color: ${colors.whiteStrong};
    font-size: 21px;
    line-height: 24px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 700px;
    margin-left: 50%;
    position: absolute;
    top: 50vh;
    transform: translateY(-50%) translateX(-50%);
    align-items: center;
    justify-content: center;

    > div {
        display: flex;
        gap: 16px;
        align-items: center;
        justify-content: center;

        > span {
            width: 250px;
        }
    }
`;

const ErrorDiv = styled.div<{withError?: boolean, withSuccess?: boolean}>`
    padding: 8px;
    min-height: 16px;
    font-size: 16px;
    line-height: 21px;
    width: 756px;
    text-align: center;
    color: ${p => p.withSuccess ? colors.success :colors.errorBg};
    background: ${p => p.withError ? colors.white : 'inherit'};
    border-radius: 4px;
    flex-direction: column;
    gap: 8px;
    position: absolute;
    transform: translateX(-50%);
    bottom: 20px;
    margin-left: 50vw;
`;