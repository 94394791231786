import React, { 
    FC,
    useEffect,
    useState,
} from "react";

import IPlayer from "../../types/Player.types";
import styled from "styled-components";
import { PlayerShowdown } from "./PlayerShowdown";
import { cloneDeep } from "lodash";
import { Tag } from "../commons/Tag";
import ITag from "../../types/Tag.types";
import { 
    IoRemoveOutline,
    IoAddOutline,
    IoSave, 
} from "react-icons/io5";

import { colors } from "../../styles/colors";
import IAction from "../../types/Action.types";
import ICard from "../../types/Card.types";


interface IShowdown {
    players: IPlayer[];
    larger: boolean;
    bb: number;
    heroNick: string;
    allTags: ITag[];
    actions: IAction[][];
    board: ICard[];
}

export const Showdown: FC<IShowdown> = ({ 
    players,
    larger,
    bb,
    heroNick,
    allTags,
    board,
}) => {

    const finalPlayers = cloneDeep(players);
    const [selectedPlayer, setSelectedPlayer] = useState<IPlayer | null>(null);
    const [isChanged, setIsChanged] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [isSaved, setIsSaved] = useState<boolean>(false);

    const user = JSON.parse(sessionStorage?.getItem('user') || '{}');
    const groupId = user?.groupId;

    useEffect(() => {
        if (isChanged && selectedPlayer !== null) {
            const actualPlayer = players.find(player => player.uuid === selectedPlayer.uuid);
            if (!!actualPlayer) {
                actualPlayer.tags = selectedPlayer?.tags;
            }
        }
    }, [selectedPlayer]);

    return (<ShowdownContainer>
        <h3>Showdown</h3>
        <Row larger={larger}>
        {
            finalPlayers.map(player => player.cards.length > 0 && (<PlayerShowdown 
                board={board}
                isSelected={player.nick === selectedPlayer?.nick}
                player={player}
                bb={bb}
                heroNick={heroNick}
                onClick={() => {
                    setIsChanged(false);
                    setIsError(false);
                    setIsSaved(false);
                    setSelectedPlayer(player.nick !== selectedPlayer?.nick ? player : null);
                }}/>))
        }
        </Row>
        {
            !!selectedPlayer && (<TagsContainer>
                <TagsHeader fontSize={18} fontWeight={600}>SELECTED TAGS</TagsHeader>
                <SubRow>
                {
                    selectedPlayer.tags?.filter(tag => !!tag)?.map((tag: ITag) => (<TagRow isSelected={true}>
                        <Tag 
                            uuid={tag.uuid} 
                            name={tag.name} 
                            color={tag.color} 
                            bgColor={tag.bgColor}/>
                        <IoRemoveOutline onClick={() => {
                            fetch(`http://146.59.44.6:8080/tagOwner/hand/delete${!!groupId ? '?groupId=' + groupId : ''}`,{ 
                                method: "DELETE",
                                mode: 'cors',
                                headers: { 'Content-Type': 'application/json'},
                                body: JSON.stringify({
                                    tagId: tag.uuid,
                                    handPlayerId: selectedPlayer.uuid
                                })
                            }).then(res => {
                                if (res.ok) {
                                    setIsSaved(true);
                                    setIsError(false);
                                    return res.json();
                                } else {
                                    setIsError(true);
                                    return {}
                                }
                            })
                            .then(json => {
                                setSelectedPlayer((prevSelectedPlayer: IPlayer | null) => {
                                    return !!prevSelectedPlayer ? {
                                        ...prevSelectedPlayer,
                                        tags: selectedPlayer.tags?.filter(innerTag => !!innerTag)?.filter((innerTag: ITag) => innerTag.uuid !== tag.uuid)
                                    } : null;
                                });
                            }).catch(e => setIsError(true));
                        }}/>
                    </TagRow>))
                }
                </SubRow>
                { isError && (<ErrorDiv>ERROR DURING SAVING TAGS</ErrorDiv>) }
                { isSaved && (<InfoDiv>TAGS SAVED</InfoDiv>) }
                <TagsHeader fontSize={14} fontWeight={400}>REST TAGS</TagsHeader>
                <SubRow>
                {
                    allTags.filter((tag: ITag) => !selectedPlayer.tags?.find(innerTag => innerTag.uuid === tag.uuid)).map((tag: ITag) => (<TagRow isSelected={false}>
                        <Tag 
                            uuid={tag.uuid} 
                            name={tag.name} 
                            color={tag.color} 
                            bgColor={tag.bgColor}/>
                        <IoAddOutline  onClick={() => {
                            fetch(`http://146.59.44.6:8080/tagOwner/hand/save${!!groupId ? '?groupId=' + groupId : ''}`,{ 
                                method: "POST",
                                mode: 'cors',
                                headers: { 'Content-Type': 'application/json'},
                                body: JSON.stringify({
                                    tagId: tag.uuid,
                                    handPlayerId: selectedPlayer.uuid
                                })
                            }).then(res => {
                                if (res.ok) {
                                    setIsSaved(true);
                                    setIsError(false);
                                    return res.json();
                                } else {
                                    setIsError(true);
                                    return {}
                                }
                            })
                            .then(() => {
                                setSelectedPlayer((prevSelectedPlayer: IPlayer | null) => {
                                    const newTags = prevSelectedPlayer?.tags;
                                    !!newTags && !prevSelectedPlayer?.tags?.includes(tag) && newTags.push(tag);
                                    return !!prevSelectedPlayer ? {
                                        ...prevSelectedPlayer,
                                        tags: newTags || []
                                    } : null;
                                });
                            }).catch(e => setIsError(true));
                        }}/>
                    </TagRow>))
                }
                </SubRow>
            </TagsContainer>)
        }
    </ShowdownContainer>);
}

const Row = styled.div<{larger?: boolean}>`
    display: flex;
    flex-wrap: wrap;
    gap: 32px;

    > div {
        width: ${p => p.larger ? 'calc(33.3% - 24px)' : 'calc(50% - 16px)'};
    }
`;

export const SubRow = styled.div`
    display: flex;
    gap: 16px;
    flex-flow: wrap;
    align-items: center;
    justify-content: center;
`;

const ShowdownContainer = styled.div`
    padding: 32px 16px;

    h3 {
        margin: 0;
        margin-bottom: 16px;
        font-family: sans-serif;
    }
`;

export const TagsContainer = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 32px;
`;

export const TagRow = styled.div<{isSelected?: boolean}>`
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
    border: 2px solid ${p => p.isSelected ? colors.success : colors.failureStrong};
    border-radius: 4px;
    padding: 4px;
`;

export const StyledIoSave = styled(IoSave)`
    position: relative;
    cursor: pointer;

    :hover {
        opacity: 0.8;
    }
`;

export const ErrorDiv = styled.div`
    color: ${colors.failureStrong};
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
`;

export const InfoDiv = styled(ErrorDiv)`
    color: ${colors.success};
`;

export const TagsHeader = styled.div<{fontSize: number, fontWeight: number, isClickable?: boolean}>`
    font-size: ${p => p.fontSize}px;
    font-weight: ${p => p.fontWeight};
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    cursor: ${p => p.isClickable && 'pointer'};
`;