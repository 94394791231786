import { styled } from "styled-components"
import { colors } from "../../../styles/colors"

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
    color: ${colors.whiteStrong};
    width: 1800px;
    @media (max-width: 2000px) {
        width: calc(100vw - 620px);
    }
    margin: 32px auto;
    align-items: flex-end;

    > div {
        display: flex;
        flex-direction: column;
        gap: 8px;
        text-transform: uppercase;

        .rmsc.multi-select {
            min-width: 250px;
            width: 250px;
        }

        .dropdown-heading-value {
            min-width: 150px;
            width: 150px;
            color: ${colors.black};
        }

        .dropdown-content {
            min-width: 250px;
            width: 250px;
            color: ${colors.black};
        }

        button {
            padding: 8px;
            font-size: 16px;
            line-height: 24px;
            border-radius: 8px;
            border: none;
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }
        }

        &.search > button {
            color: ${colors.whiteStrong};
            background-color: ${colors.buttons.download};
        }

        &.clear > button {
            color: ${colors.black};
            background-color: ${colors.buttons.details};
        }
    }
`