export enum COLOR {
    SPADES = 's',
    HEARTS = 'h',
    CLUBS = 'c',
    DIAMOND = 'd'
}

export enum FIGURE {
    A = 'A',
    K = 'K',
    Q = 'Q',
    J = 'J',
    F10 = 'T',
    F9 = '9',
    F8 = '8',
    F7 = '7',
    F6 = '6',
    F5 = '5',
    F4 = '4',
    F3 = '3',
    F2 = '2'
}

export default interface ICard {
    color: COLOR,
    figure: FIGURE,
    boardId?: number
}