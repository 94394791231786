import React, { FC } from "react";

import IPlayer from "../../types/Player.types";
import styled from "styled-components";
import { Card } from "../commons/Card";
import { colors } from "../../styles/colors";
import IAction from "../../types/Action.types";
import ICard from "../../types/Card.types";
import { HandPokerSolver } from "../pages/AddHand";
import { cloneDeep } from "lodash";

interface IPlayerShowdown {
    player: IPlayer;
    bb: number;
    heroNick: string;
    onClick: (player: IPlayer | null) => void;
    isSelected: boolean;
    board: ICard[];
};

const boardSize = [0, 3, 4, 5];

export const PlayerShowdown: FC<IPlayerShowdown> = ({ 
    player,
    bb,
    heroNick,
    onClick,
    isSelected,
    board,
}) => {
    const actualAllCards: string[] = [];
    cloneDeep(board).forEach(card => {
        if (!actualAllCards.includes(card.figure + card.color)) {
            actualAllCards.push(card.figure + card.color);
        }
    });
    console.log(actualAllCards);
    player?.cards?.forEach(card => actualAllCards.push(card.figure + card.color));
    console.log(actualAllCards);
    const handSolver = actualAllCards.length > 0 && HandPokerSolver.solve(actualAllCards);

    return (<Row gap={16} onClick={() => isSelected ? onClick(null) : onClick(player)} isSelected={isSelected} cursor={'pointer'}>
        <Row gap={4}>
        {
            player.cards.map(card => <Card background={colors.cards[card.color]} figure={card.figure} />)
        }
        </Row>
        <Column gap={4}>
            <Nick highlighed={player.nick === heroNick}>{ player.nick }</Nick>
            <div>{ handSolver?.name }</div>
            { !!player?.balance && (<Result success={player.balance > 0}>{ player.balance > 0 ? 'Won' : 'Lost' } { Math.abs(Math.round(player.balance / bb)) } bb</Result>) }
        </Column>
    </Row>);
};

const Row = styled.div<{gap?: number, isSelected?: boolean, cursor?: string}>`
    display: flex;
    align-items: center;
    gap: ${p => p.gap}px;
    color: ${colors.soft};
    font-family: sans-serif;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.01rem;

    background-color: ${p => p.isSelected && colors.highlightedPlayer} !important;
    cursor: ${p => p.cursor};
`;

const Column = styled.div<{gap?: number}>`
    display: flex;
    flex-direction: column;
    gap: ${p => p.gap}px;
`;

const Nick = styled.div<{highlighed: boolean}>`
    color: ${p => p.highlighed && colors.highlight};
`;

const Result = styled.div<{success: boolean}>`
    color: ${p => p.success ? colors.success : colors.failure};
`;