import React, { 
    FC, 
    useRef,
    useState, 
} from "react";

import IHand from "../../types/Hand.types";
import { PlayerHand } from "./PlayerHand";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import IPlayer from "../../types/Player.types";
import ITag from "../../types/Tag.types";
import { Tag } from "../commons/Tag";

interface IPlayerHands {
    hands: IHand[];
    openDetails?: (handUuid: string) => void;
    heroNick: string;
    onSelectHand?: (hand: IHand | null) => void;
    onGlobalClick?: (hand: IHand | null) => void;
    selectedHandUuid: string;
    hideTotalBalance?: boolean;
    polishTime?: boolean;
    available?: boolean;
    showIsReviewed?: boolean;
}

const haveCommonItems = (arr1: ITag[], arr2: ITag[]) => {
    const commonItems = arr2?.filter(innerTag => !!innerTag).filter(item2 => arr1.find((item1: ITag) => item1.uuid === item2.uuid) !== undefined);
    return commonItems.length > 0;
}

const numberWithSpaces = (x: number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export const PlayerHands: FC<IPlayerHands> = ({ 
    hands,
    openDetails,
    heroNick,
    onSelectHand,
    selectedHandUuid,
    hideTotalBalance,
    polishTime,
    onGlobalClick,
    available,
    showIsReviewed
}) => {

    const balances = hands?.map(hand => hand?.players?.filter(player => player.nick === heroNick)?.[0]?.balance);
    const totalBalance = (!!balances && balances.length > 0  && balances?.reduce((b1, b2) => (b1 || 0) + (b2 || 0)) || 0);
    const availableTags = !!heroNick.trim() ? hands?.map((hand: IHand) => hand.players
        .filter(player => player.nick.trim() == heroNick.trim())
        .map((player: IPlayer) => player.tags)
        .reduce((tags1, tags2) => [...tags1, ...tags2]), [])
        .reduce((tags1, tags2) => [...tags1, ...tags2], [])
        .filter((value, index, self) => self.findIndex(v => v.name === value.name) === index) : [];

    const myRef = useRef<null | HTMLDivElement>(null);

    const [selectedTags, setSelectedTags] = useState<ITag[]>([]);
    const [isAsc, setIsAsc] = useState<boolean>(false);
    const [isDesc, setIsDesc] = useState<boolean>(false);

    return (<PlayerHandsContainer available={available}>
        { !hideTotalBalance && (<TotalBalance isSuccess={totalBalance > 0}>
                <div>TOTAL BALANCE: </div>
                <div>{ numberWithSpaces(Math.round(totalBalance / 1000)) } k</div>
            </TotalBalance>)
        }
        { !hideTotalBalance && (<TagsFilter>
            {
                availableTags?.map((tag: ITag) => (<Tag 
                    uuid={tag.uuid} 
                    name={tag.name} 
                    color={tag.color} 
                    bgColor={tag.bgColor}
                    isSelected={selectedTags?.filter(innerTag => !!innerTag).find((innerTag: ITag) => innerTag.uuid === tag.uuid) !== undefined}
                    onClick={(tag) => {
                        const newSelectedTags = selectedTags?.filter(innerTag => !!innerTag).find((innerTag: ITag) => innerTag.uuid === tag.uuid) !== undefined ? 
                            selectedTags.filter((innerTag: ITag) => innerTag.uuid !== tag.uuid) :
                            [tag].concat(selectedTags);
                        setSelectedTags(newSelectedTags);
                    }}/>))
            }
            </TagsFilter>)
        }
        <Column ref={myRef}>
        { !hideTotalBalance && (<OrderDiv>
                <div className={isAsc && 'selected' || ''} onClick={() => {
                    setIsDesc(false);
                    setIsAsc((prevState: boolean) => !prevState)
                }}>BEST</div>
                <div className={isDesc && 'selected' || ''} onClick={() => {
                    setIsAsc(false);
                    setIsDesc((prevState: boolean) => !prevState)
                }}>WORST</div>
            </OrderDiv>)
        }
        { hands.length > 0 ? hands.map(hand => {
            if (hideTotalBalance) {
                let datetime = hand.date.split(' ');
                if (datetime.length > 2) {
                    datetime = [datetime[0], datetime[2]];
                }
                
                if (datetime?.[1].length < 8) { 
                    datetime[1] = '0' + datetime[1];
                }
                hand.date = (datetime?.[0] + ' ' + datetime?.[1]).trim();
            }
            return hand;
        }).sort((hand1: IHand, hand2: IHand) => {
            const hero1 = (isAsc || isDesc) ? (hand1.players.filter(player => player.nick === heroNick)[0]?.balance || 0) / hand1.bb : 0;
            const hero2 = (isAsc || isDesc) ? (hand2.players.filter(player => player.nick === heroNick)[0]?.balance || 0) / hand2.bb : 0;
            return (isAsc ? (hero2 - hero1) : (isDesc ? (hero1 - hero2) : 1))
        })
        .map((hand, idx) => (!!hand 
            && (selectedTags.length === 0 || haveCommonItems(selectedTags, hand?.players?.find((player: IPlayer) => player.nick === heroNick)?.tags || [])) 
            && (<PlayerHand
                showIsReviewed={showIsReviewed}
                polishTime={polishTime}
                hideFinalBalance={hideTotalBalance}
                selectedHandUuid={selectedHandUuid}
                heroNick={heroNick}
                key={idx}
                hand={hand} 
                showHeroHand={true}
                openDetails={openDetails}
                onGlobalClick={onGlobalClick}
                onSelectHand={() => {
                    (!!onSelectHand && (hand?.uuid === selectedHandUuid ? onSelectHand(null) :onSelectHand(hand)));
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });
                }}/>))) : <div>NO HANDS TO PRESENT</div> }
        </Column>
    </PlayerHandsContainer>);
};

const Column = styled.div`
    padding: 32px;
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: fit-content;

    > div {
        border-top: 2px solid ${colors.soft};
    }

    > div:first-child {
        border-top: none;
    }
`;

const OrderDiv = styled.div`
    margin: 0 auto 16px auto;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: 16px;

    > div {
        cursor: pointer;
    }

    > div:hover {
        opacity: 0.8;
    }

    div.selected {
        text-decoration: underline;
        font-weight: 600;
    }
`;

const PlayerHandsContainer = styled.div<{available?: boolean}>`
    background-color: ${colors.background.primary};
    color: ${colors.whiteStrong};
    
    ${p => !p.available && `
        opacity: 0.25;
        pointer-events: none;
    `}
`;

const TotalBalance = styled.div<{isSuccess: boolean}>`
    display: flex;
    gap: 16px;
    font-size: 24px;
    line-height: 40px;
    font-weight: 600;
    justify-content: center;
    margin-top: 16px;

    > div:last-child {
        color: ${p => p.isSuccess ? colors.success : colors.failureStrong};
    }
`;

const TagsFilter = styled.div`
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: center;
    padding: 16px;
`;