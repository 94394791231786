export const colors = {
    background: {
        primary: '#2B2B2B',
        secondary: '#3C3C3C',
        secondaryWithOpacity: 'rgba(64, 64, 64, 0.8)',
    },
    font: {
        primary: ''
    },
    button: {
        primary: {
            background: '',
            hover: ''
        },
        secondary: {
            background: '',
            hover: ''
        }
    },
    tile: {
        background: '#1C221E'
    },
    whiteStrong: '#FFFFFF',
    black: '#000000',
    red: '#ff0000',
    redLight: '#993300',
    white: 'rgba(255, 255, 255, 0.8)',
    soft: '#888888',
    border: '#323734',
    cards: {
        c: '#155E15',
        d: '#14344D',
        s: '#474747',
        h: '#791D1D'
    },
    actionBg: '#232926',
    highlight: '#74D16E',
    success: '#B6EB8E',
    failure: '#ED9081',
    failureStrong: '#FF7061',
    buttons: {
        call: '#FF6B6B',
        details: '#F4F4F4',
        download: '#007AD9',
        delete: '#ED5E68'
    },
    player: [
        '#FF5B5B',
        '#FF9B9B',
        '#FF9D1D',
        '#EEEE2A',
        '#01FF00',
        '#01AA00',
        '#01FFFF',
        '#5DA0FF',
        '#1D60EE'
    ],
    infoBg: '#FFC007',
    errorBg: '#F44336',
    highlightedPlayer2: '#222222',
    highlightedPlayer: '#303030',
    highlightedNote: '#444444',
    betTypes: {
        'p': '#FF0000',
        'h': '#FF9900',
        'q': '#FF00FF',
        'm': '#8E7CC3'
    },
    pokerTable: '#006400'
}