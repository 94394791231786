import React, { FC } from "react";

import './App.css';
import {
  Route, 
  Routes 
} from "react-router-dom";
import { Players } from "./components/pages/Players";
import { PlayersList } from "./components/pages/PlayersList";
import { AddHand } from "./components/pages/AddHand";
import { TagsPage } from "./components/pages/TagsPage";
import { Login } from "./components/pages/Login";
import { TagsPlayerPage } from "./components/pages/TagsPlayerPage";
import { Hands } from "./components/pages/Hands";
import { Register } from "./components/pages/Register";
import { Tables } from "./components/pages/Tables";
import { Calendar } from "./components/pages/Calendar/Calendar";
import { Groups } from "./components/pages/Gropus/Groups";
import { Users } from "./components/pages/Users/Users";

const RoutesContainer: FC = () => {

  return (<Routes>
    <Route
        path='/players/:nick/hands'
        element={<Players />}
    />
    <Route
        path='/players/tag/:tagId'
        element={<PlayersList />}
    />
    <Route
        path='/players'
        element={<PlayersList />}
    />
    <Route
        path='/'
        element={<AddHand />}
    />
    <Route
        path='/tags'
        element={<TagsPage />}
    />
    <Route
        path='/tagsPlayers'
        element={<TagsPlayerPage />}
    />
    <Route
        path='/login'
        element={<Login />}
    />
    <Route
        path='/register'
        element={<Register />}
    />
    <Route
        path='/tables'
        element={<Tables />}
    />
    <Route
        path='/calendar'
        element={<Calendar />}
    />
    <Route
        path='/hands/:tagId?'
        element={<Hands />}
    />
    <Route
        path='/groups'
        element={<Groups />}
    />
    <Route
        path='/users'
        element={<Users />}
    />
  </Routes>)
};

export const App: FC = () => {
  return (<>
    <RoutesContainer />
  </>);
};