import React, { FC } from "react";

import IAction from "../../types/Action.types";
import { Action } from "./Action";
import IPlayer from "../../types/Player.types";

interface IActions {
    actions: IAction[];
    heroNick: string;
    players: IPlayer[];
    colorIndexes: number[];
}

export const Actions: FC<IActions> = ({ 
    actions,
    heroNick,
    players,
    colorIndexes
}) => {
    return (<>{ actions.sort((action1, action2) => (action1?.actionId || 0) - (action2?.actionId || 0)).map(action => (<Action 
        action={action} 
        heroNick={heroNick} 
        players={players}
        colorIndexes={colorIndexes}
    />)) } </>);
}