import React, { 
    FC, 
    useEffect, 
    useState, 
} from 'react';

import { styled } from 'styled-components';
import { colors } from '../../../styles/colors';
import { 
    IDayView, 
    TestDiv, 
} from './Calendar.utils';
import ITag from '../../../types/Tag.types';
import { onlyUnique } from './MonthView';

export const DayView: FC<IDayView> = ({
    date,
    items,
    tags,
}) => {

    const day = date.toLocaleString('en-US', {day: 'numeric'});
    const weekday = date.toLocaleString('en-US', {weekday: 'short'});
    const [finalItems, setFinalItems] = useState<any[]>([]);

    useEffect(() => {
        const internalItems: any[] = [];
        items.map(item => {
            const date = item?.date;
            const nick = item?.nick;
            const hourMinutePart = date?.split(' ')[1].split(':');
            const moveY = (hourMinutePart?.[0] * 42 + hourMinutePart?.[1] * 42/60 - 21) || 0;
            item?.tags.map((tag: ITag) => {
                internalItems.push({
                    tag: tag,
                    date: date,
                    moveY: moveY,
                    height: 42,
                    idx: tags.indexOf(tag),
                    nick: nick
                })
            });
        });
        let internalFinalItems: any[] = [];
        tags.forEach((tag, idx) => {
            const list = internalItems.filter(internalItem => internalItem.tag.uuid === tag.uuid).sort((item1, item2) => item1.moveY - item2.moveY);
            let newItem = {
                tag: tag,
                date: date,
                moveY: list?.[0]?.moveY,
                height: 42,
                idx: list?.[0]?.idx,
                nicks: [list?.[0]?.nick]
            };
            for (let i = 1; i < list.length; i++) {
                const internalItem = list[i];
                const internaHeight = (internalItem?.moveY + internalItem?.height) - (newItem?.moveY + newItem?.height);
                if (internaHeight >= 0 && internaHeight <= 42) {
                    newItem.nicks.push(internalItem?.nick);
                    newItem.height += internaHeight; 
                } else {
                    internalFinalItems.push(newItem);
                    newItem = {
                        tag: tag,
                        date: date,
                        moveY: internalItem?.moveY,
                        height: 42,
                        idx: internalItem?.idx,
                        nicks: [internalItem?.nick]
                    };
                }
            }
            internalFinalItems.push(newItem);
        });
        setFinalItems(internalFinalItems);
    }, [items, tags]);

    return (<>
        <Heading bgColor={true}>
            <span>{ weekday }</span>
            <span>{ day }</span>
        </Heading>
        <TagsHeader>
        {
            tags.map(tag => <TagItem bgColor={tag?.bgColor} color={tag?.color}>{tag?.name}</TagItem>)
        }
        </TagsHeader>
        <Hours>
        {
            Array.from(Array(24).keys()).map(hour => (<div>
                <span>{hour}</span>
            </div>))
        }
        </Hours>
        { finalItems.map(item => (<TestDiv 
            moveX={item?.idx} 
            moveY={item?.moveY}
            height={item?.height}
            bgColor={item?.tag?.bgColor}
            color={item?.tag?.color}>
            <div>{ item?.nicks.filter(onlyUnique)?.length }</div>
        </TestDiv>))
        }
    </>);
};

export const TagsHeader = styled.div`
    display: flex;
    padding: 8px;
    padding-left: 64px;
    box-sizing: border-box;
    gap: 8px;
    background-color: ${colors.white};
`;

export const TagItem = styled.div<{color: string, bgColor: string}>`
    color: ${p => p.color};
    background-color: ${p => p.bgColor};
    box-sizing: border-box;
    width: 194px;
    text-align: center;
    padding: 8px;
`;

export const Heading = styled.div<{bgColor?: boolean}>`
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
    padding-bottom: 0;
    background-color: ${p => p.bgColor && colors.white};
    color: ${colors.black};
    
    > span {
        width: 100px;
        text-align: center;
    }
`;

export const Hours = styled.div`
    background-color: ${colors.white};
    color: ${colors.black};
    padding-right: 24px;
    padding-left: 24px;
    padding-bottom: 36px;
    margin-bottom: 36px;

    > div {
        padding-top: 8px;
        padding-bottom: 8px;
        border-bottom: 2px solid ${colors.black};
        margin-left: 40px;
        min-height: 24px;

        > span {
            position: absolute;
            margin-left: -36px;
            margin-top: 24px;
            width: 24px;
            text-align: right;
        }
    }
`;