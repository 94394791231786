import React, { 
    FC, 
    useEffect, 
    useState,
} from "react";

import styled from "styled-components";
import { colors } from "../../styles/colors";
import { SubheaderContainer } from "../commons/Subheader";
import { StyledButton } from "../commons/Button";
import { useParams } from "react-router-dom";
import { AutoComplete, AutoCompleteInput, AutoCompleteItem, AutoCompleteList, Item } from "@choc-ui/chakra-autocomplete";
import IPlayerGlobal from "../../types/IPlayerGlobal";

interface ISearchBox {
    onSearch: (nick: string) => void;
    setHeroNick: (nick: string) => void;
    isLoading: boolean;
};

export const searchBoxAsianRegex = new RegExp('^.*([^a-zA-Z0-9])+.*$');

export const SearchBox: FC<ISearchBox> = ({
    onSearch,
    setHeroNick,
    isLoading,
}) => {

    const params = useParams();
    const [nick, setNick] = useState<string>(params?.nick || '');
    const [players, setPlayers] = useState<IPlayerGlobal[]>([]);

    const user = JSON.parse(sessionStorage?.getItem('user') || '{}');
    const userId = user?.uuid;
    const groupId = user?.groupId;
    const isAdmin = user?.admin;

    useEffect(() => {
        fetch(`http://146.59.44.6:8080/player${!!groupId ? '?groupId=' + groupId : ''}`, { 
            method: "GET",
            headers: { 'Content-Type': 'application/json'}
        }).then(res => res.ok ? res.json() : [])
        .then(json => {
            setPlayers(json);
        });

        if (!!params?.nick) {
            onSearch(nick);
        }
    }, []);

    useEffect(() => {
        setHeroNick(nick);
        onSearch(nick);
    }, [nick]);

    return (<SubheaderContainer>
        <StyledAutoComplete openOnFocus onChange={(value) => setNick(value)} value={nick} filter={(query: string, optionValue: Item["value"], optionLabel: Item["label"]) => {
            return query[0] === '.' ? searchBoxAsianRegex.test(String(optionValue)) : String(optionValue).startsWith(query);
        }}>
          <AutoCompleteInput variant="filled" height={32} width={400} borderRadius={12} fontSize={18}/>
          <AutoCompleteList>
            { 
                players.map((player) => (
                <AutoCompleteItem
                    fontSize={18}
                    backgroundColor={colors.white}
                    color={colors.border}
                    _hover={{backgroundColor: colors.border, color: colors.white, opacity: 1}}
                    padding={8}
                    key={player?.playerNick}
                    value={player?.playerNick}
                    align="center"
                >{ player.playerNick }</AutoCompleteItem>
                ))
            }
          </AutoCompleteList>
        </StyledAutoComplete>
        <StyledButton 
            isActive={!isLoading}
            disabled={isLoading}
            onClick={() => onSearch(nick)}
            backgroundColor={colors.buttons.download}>
            SEARCH
        </StyledButton>
    </SubheaderContainer>);
};

const StyledAutoComplete = styled(AutoComplete)`
    width: 400px;

    font-size: 20px;
    text-align: right;
    box-sizing: border-box;
    padding: 0 20px;
    border-radius: 16px;
`;