import React, { 
    FC, 
    useEffect, 
    useState, 
} from "react";

import { INote } from "../../types/Note.types";
import { Textarea } from "@chakra-ui/react";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import { ButtonsRow } from "../PlayerHands/PlayerHand";
import { StyledButton } from "../commons/Button";
import { IoRemove } from "react-icons/io5";
import IPlayerGlobal from "../../types/IPlayerGlobal";

interface IPlayerNotes {
    nick: string;
    playerUuid: string | undefined;
    handsSize: number;
}

export const PlayerNotes: FC<IPlayerNotes> = ({ 
    nick,
    playerUuid,
    handsSize,
}) => {

    const [selectedNote, setSelectedNote] = useState<INote | null>(null);
    const [notes, setNotes] = useState<INote[]>([]);
    const [playerNotes, setPlayerNotes] = useState<INote[]>([]);
    const [player, setPlayer] = useState<IPlayerGlobal>();
    const [isError, setIsError] = useState<boolean>(false);
    const [isDeleted, setIsDeleted] = useState<boolean>(false);
    const [newNote, setNewNote] = useState<string>('');

    const user = JSON.parse(sessionStorage?.getItem('user') || '{}');
    const userId = user?.uuid;
    const groupId = user?.groupId;
    const isAdmin = user?.admin;

    useEffect(() => {
        fetch(`http://146.59.44.6:8080/note?nick=${nick}${!!groupId ? '&groupId=' + groupId : ''}`,{ 
            method: "GET",
            headers: { 'Content-Type': 'application/json'}
        }).then(res => {
            if (res.ok) {
                return res.json()
            } else {
                return null
            }
        }).then(json => !!json && setNotes(json))
        .catch(e => setIsError(true));


        fetch(`http://146.59.44.6:8080/notePlayer?nick=${nick}${!!groupId ? '&groupId=' + groupId : ''}`,{ 
            method: "GET",
            headers: { 'Content-Type': 'application/json'}
        }).then(res => {
            if (res.ok) {
                return res.json()
            } else {
                return null
            }
        }).then(json => !!json && setPlayerNotes(json))
        .catch(e => setIsError(true));


        fetch(`http://146.59.44.6:8080/player/${nick}${!!groupId ? '?groupId=' + groupId : ''}`,{ 
            method: "GET",
            headers: { 'Content-Type': 'application/json'}
        }).then(res => {
            if (res.ok) {
                return res.json()
            } else {
                return null
            }
        }).then(json => !!json && setPlayer(json))
        .catch(e => setIsError(true))
    }, [nick]);

    const actualNotes = !!playerUuid ? notes?.filter((note: INote) => note?.player?.uuid === playerUuid) : playerNotes;

    return (<Container>
        <HeroNick><div>{ nick } ({handsSize})</div></HeroNick>
        <StyledTextarea 
            resize={'none'} 
            rows={2} 
            height={64} 
            value={!!selectedNote ? selectedNote.note : newNote} 
            onChange={(e) => {!!selectedNote ? setSelectedNote((prevNote: INote | null) => {
                return !!prevNote ? {
                    ...prevNote,
                    note: e.target.value
                } : null
            }) : setNewNote(e.target.value)}}/>
        <StyledButtonsRow gap={16}>
            <StyledButton 
                width={100}
                bgColor={colors.buttons.download} 
                disabled={!selectedNote && newNote.length === 0} 
                isActive={!!selectedNote || newNote.length !== 0}
                onClick={() => {
                    if (!!playerUuid) {
                        if (!!selectedNote) {
                            fetch(`http://146.59.44.6:8080/note${!!groupId ? '?groupId=' + groupId : ''}`, { 
                                method: "POST",
                                mode: 'cors',
                                headers: { 'Content-Type': 'application/json'},
                                body: JSON.stringify(selectedNote)
                            }).then(res => {
                                if (res.ok) {
                                    return res.json()
                                } else {
                                    return null
                                }
                            }).then(json => {
                                if (!json) { 
                                    setIsError(true);
                                 } else {
                                    const actualNote = notes.find((note: INote) => note.uuid === selectedNote.uuid);
                                    if (!!actualNote) {
                                        actualNote.note = json?.note;
                                    }
                                    setSelectedNote(null);
                                    setNewNote('');
                                }
                            })
                            .catch(e => setIsError(true));
                        } else {
                            fetch(`http://146.59.44.6:8080/note${!!groupId ? '?groupId=' + groupId : ''}`, { 
                                method: "POST",
                                mode: 'cors',
                                headers: { 'Content-Type': 'application/json'},
                                body: JSON.stringify({
                                    note: newNote,
                                    player: {
                                        uuid: playerUuid
                                    }
                                })
                            }).then(res => {
                                if (res.ok) {
                                    return res.json()
                                } else {
                                    return null
                                }
                            }).then(json => {
                                if (!json) {
                                    setIsError(true)
                                } else {
                                    setNotes((prevNotes: INote[]) => {
                                        prevNotes = prevNotes.filter((innerNote: INote) => innerNote.uuid !== json.uuid);
                                        prevNotes.push(json);
                                        return prevNotes;
                                    });
                                    setNewNote('');
                                }
                            }).catch(e => setIsError(true));
                        }
                    } else {
                        fetch(`http://146.59.44.6:8080/notePlayer${!!groupId ? '?groupId=' + groupId : ''}`,{ 
                            method: "POST",
                            mode: 'cors',
                            headers: { 'Content-Type': 'application/json'},
                            body: JSON.stringify({
                                uuid: !!selectedNote && selectedNote.uuid,
                                note: !!selectedNote ? selectedNote.note : newNote,
                                player: {
                                    uuid: player?.uuid
                                }
                            })
                        }).then(res => {
                            if (res.ok) {
                                return res.json()
                            } else {
                                return null
                            }
                        }).then((json: INote) => {
                            if (!json) {
                                setIsError(true)
                            } else {
                                setNewNote('');
                                setPlayerNotes((prevNotes: INote[]) => {
                                    prevNotes = prevNotes.filter((innerNote: INote) => innerNote.uuid !== json.uuid);
                                    prevNotes.push(json);
                                    return prevNotes;
                                });
                            }
                        }).catch(e => setIsError(true));
                    }
                }}>{!!selectedNote ? 'SAVE' : 'ADD' }</StyledButton>
            <StyledButton 
                width={100}
                bgColor={colors.buttons.delete} 
                disabled={!selectedNote && newNote.length === 0} 
                isActive={!!selectedNote || newNote.length !== 0}
                onClick={() => {
                    setSelectedNote(null);
                    setNewNote('');
                }}>CLEAR</StyledButton>
        </StyledButtonsRow>
        <HeroNick marginTop={0}>
            <div><small>{!!playerUuid ? 'hand note mode' : 'player note mode'}</small></div>
        </HeroNick>
        <Notes>
        {
            actualNotes.map((note: INote, id: number) => (<Note isSelected={!!selectedNote && selectedNote.uuid === note.uuid}>
                <div>
                    <div>{ id+1 }</div>
                    <div>{ note.note }</div>
                </div>
                <div>
                    <StyledButton 
                        width={100}
                        isActive={true}
                        bgColor={colors.button.primary.background}
                        onClick={() => note.uuid !== selectedNote?.uuid ? setSelectedNote(note) : setSelectedNote(null)}>{note.uuid !== selectedNote?.uuid ? 'EDIT' : 'BACK'}</StyledButton>
                    <StyledButton 
                        isActive={true} 
                        bgColor={colors.buttons.delete}
                        onClick={() => {
                            if (!!playerUuid) {
                                fetch(`http://146.59.44.6:8080/note${!!groupId ? '?groupId=' + groupId : ''}`,{ 
                                    method: "DELETE",
                                    mode: 'cors',
                                    headers: { 'Content-Type': 'application/json'},
                                    body: JSON.stringify(note)
                                }).then(res => {
                                    if (res.ok) {
                                        return res.json()
                                    } else {
                                        return null
                                    }
                                }).then(json => {
                                    if (!json) {
                                        setIsError(true) 
                                    } else {
                                        setIsDeleted(true);
                                        setNotes((prevNotes: INote[]) => prevNotes.filter((innerNote: INote) => innerNote.uuid !== json.uuid));
                                    }
                                })
                                .catch(e => setIsError(true));
                            } else {
                                fetch(`http://146.59.44.6:8080/notePlayer${!!groupId ? '?groupId=' + groupId : ''}`,{ 
                                    method: "DELETE",
                                    mode: 'cors',
                                    headers: { 'Content-Type': 'application/json'},
                                    body: JSON.stringify(note)
                                }).then(res => {
                                    if (res.ok) {
                                        return res.json()
                                    } else {
                                        return null
                                    }
                                }).then(json => {
                                    if (!json) {
                                        setIsError(true) 
                                    } else {
                                        setIsDeleted(true);
                                        setPlayerNotes((prevNotes: INote[]) => prevNotes.filter((innerNote: INote) => innerNote.uuid !== json.uuid));
                                    }
                                })
                                .catch(e => setIsError(true));
                            }

                            
                        }}>
                        <IoRemove />
                    </StyledButton>
                </div>
            </Note>))
        }
        </Notes>
    </Container>);
};

const Container = styled.div`
    width: 840px;
    margin: 0 auto;
`;

const HeroNick = styled.div<{marginTop?: number}>`
    padding: 32px;
    padding-top: ${p => p.marginTop};
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    color: ${colors.white};
    text-align: center;
`;

const StyledTextarea = styled(Textarea)`
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    box-sizing: border-box;
    padding: 8px 16px;
`;

export const StyledButtonsRow = styled(ButtonsRow)`
    items-align: center;
    justify-content: center;
    width: 100%;
`;

const Notes = styled.div`
    display: flex;
    flex-direction: column;
`;

const Note = styled.div<{isSelected: boolean}>`
    background-color: ${p => p.isSelected && colors.highlightedNote};
    color: ${colors.whiteStrong};
    display: flex;
    gap: 8px;
    font-size: 16px;
    line-height: 32px;
    border-bottom: 1px solid ${colors.white};
    justify-content: space-between;
    align-items: center;
    padding: 8px;

    > div {
        display: flex;
        gap: 16px;
    }

    > div:first-child > div:first-child {
        width: 30px;
    }
`;