import { cloneDeep } from "lodash";
import styled from "styled-components";
import ITag from "../../../types/Tag.types";

export interface IDayView {
    date: Date;
    items: any[];
    tags: ITag[];
};

export const getWeek = (date: Date) => {
    const finalDate = cloneDeep(date);
    const week = [];
    const baseShift = date.getDate() - date.getDay();

    for (let i = 1; i <= 7; i++) {
        let first = baseShift + i;
        const newDate = new Date(finalDate.setDate(first));
        if (week.length > 0 && newDate.getDate() < week[week.length - 1].getDate()) {
            newDate.setMonth(newDate.getMonth() + 1);
            finalDate.setMonth(finalDate.getMonth() + 1);
        }
        week.push(newDate);
    }
    return week;
};

export const getDaysInMonth = (month: number, year: number) => {
    const date = new Date(year, month, 1);
    const days = [];
    while (date.getMonth() === month) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return days;
};

export const TestDiv = styled.div<{moveX: number, moveY: number, bgColor: string, color: string, width?: number, height?: number}>`
    position: absolute;
    width: ${p => p.width || 194}px;
    height: ${p => p.height || 32}px;
    font-size: 14px;
    color: ${p => p.color};
    background-color: ${p => p.bgColor};
    margin-left: ${p => p.moveX * (p.width ? p.width : 202) + 64}px;
    margin-top: ${p => p.moveY - 1038}px;
    text-align: center;
    display: flex;
    text-align: center;
    justify-content: center;

    > div {
        margin-top: ${p => p.height && (p.height / 2 - 10)}px
    }
`;