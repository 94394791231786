import React, { FC } from "react";

import { IDayView } from "./Calendar.utils";
import { MonthView } from "./MonthView";
import { cloneDeep } from "lodash";
import styled from "styled-components";
import { colors } from "../../../styles/colors";

interface IYearView extends IDayView {
    setCurrentMonth: (date: Date) => void;
    setCurrentDate: (date: Date) => void;
}

export const YearView: FC<IYearView> = ({
    date,
    items,
    setCurrentMonth,
    setCurrentDate,
    tags,
}) => {

    const year = date.getFullYear();

    return (<YearDiv>
    {
        Array.from(Array(12).keys()).map(key => {
            const newDate = cloneDeep(date);
            newDate.setDate(1);
            newDate.setMonth(key);
            return (<div>
                <div onClick={() => setCurrentMonth(newDate)}>{ newDate.toLocaleString('en-US', {year: 'numeric', month: 'long'}) }</div>
                <MonthView 
                    tags={tags}
                    date={newDate} 
                    withExtraDays={false} 
                    baseDate={date} 
                    items={items.filter(item => item?.date?.startsWith(newDate.toISOString().substring(0, 7)))}
                    setCurrentDate={setCurrentDate}/>
            </div>);
        })
    }
    </YearDiv>);
};

const YearDiv = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    font-size: 18px;

    > div > div:first-child {
        text-align: center;
        color: ${colors.whiteStrong};
        margin-top: 16px;
        margin-bottom: 8px;
        cursor: pointer;

        &:hover {
            opacity: 0.8;
        }
    }

    margin-bottom: 16px;
`;