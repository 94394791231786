import React, { 
    FC, 
    useEffect, 
    useState, 
} from "react";

import styled from "styled-components";
import ITag from "../../types/Tag.types";
import { 
    SubRow, 
    TagRow, 
    TagsContainer, 
    TagsHeader,
} from "../HandDetails/Showdown";
import { Tag } from "../commons/Tag";
import { 
    IoRemoveOutline, 
    IoAddOutline, 
} from "react-icons/io5";
import { colors } from "../../styles/colors";
import icons, { Icon } from "../commons/Icon";

interface IPlayerTags {
    nick: string;
    allTagsIds?: string[];
}

export const PlayerTags: FC<IPlayerTags> = ({
    nick,
    allTagsIds,
}) => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [tags, setTags] = useState<ITag[]>([]);
    const [allTags, setAllTags] = useState<ITag[]>([]);
    const [isAllTagsOpen, setIsAllTagsOpen] = useState<boolean>(false);
    const [isSuggestedTagsOpen, setIsSuggestedTagsOpen] = useState<boolean>(false);
    const [playerUuid, setPlayerUuid] = useState<string>('');

    const user = JSON.parse(sessionStorage?.getItem('user') || '{}');
    const userId = user?.uuid;
    const groupId = user?.groupId;
    const isAdmin = user?.admin;

    useEffect(() => {
        setTags([]);
        setAllTags([]);
        fetch(`http://146.59.44.6:8080/player/${nick}${!!groupId ? '?groupId=' + groupId : ''}`,{ 
            method: "GET",
            headers: { 'Content-Type': 'application/json'}
        }).then(res => {
            if (res.ok) {
                return res.json()
            } else {
                return null
            }
        }).then(json => {
            const innerPlayerUuid = json?.uuid;
            setPlayerUuid(innerPlayerUuid);
            setTags(json?.tags);
            if (!!json) {
                fetch(`http://146.59.44.6:8080/tagPlayer${!!groupId ? '?groupId=' + groupId : ''}`, { 
                    method: "GET",
                    headers: { 'Content-Type': 'application/json'}
                }).then(res => res.ok ? res.json() : [])
                .then(json => {
                    setAllTags(json);
                });
            }
        });

    }, [nick]);

    return (<Container>
        <TagsContainerExtended>
            <TagsHeader fontSize={18} fontWeight={600}>
                <span>SELECTED TAGS</span>
            </TagsHeader>
            <SubRow>
            {
                tags?.filter(innerTag => !!innerTag)?.map((tag: ITag) => (<TagRow isSelected={true}>
                    <Tag 
                        uuid={tag.uuid} 
                        name={tag.name} 
                        color={tag.color} 
                        bgColor={tag.bgColor}/>
                    <IoRemoveOutline onClick={() => {
                        fetch(`http://146.59.44.6:8080/tagOwner/player/delete${!!groupId ? '?groupId=' + groupId : ''}`,{ 
                            method: "DELETE",
                            mode: 'cors',
                            headers: { 'Content-Type': 'application/json'},
                            body: JSON.stringify({
                                tagId: tag.uuid,
                                playerId: playerUuid
                            })
                        }).then(res => res.ok ? res.json() : [])
                        .then(json => {
                            setTags((prevTags: ITag[]) => {
                                prevTags = prevTags?.filter(innerTag => !!innerTag).filter((innerTag: ITag) => innerTag.uuid !== json?.tagId);
                                return prevTags;
                            });
                        });
                    }}/>
                </TagRow>))
            }
            </SubRow>
        </TagsContainerExtended>
        {
            isAdmin && (<TagsContainerExtended>
                <TagsHeader fontSize={14} fontWeight={400} onClick={() => setIsSuggestedTagsOpen((prevState: boolean) => !prevState)} isClickable={true}>
                    <Icon 
                        onClick={() => {}}
                        icon={isSuggestedTagsOpen ? icons.UpArrowIcon : icons.DownArrowIcon}
                        height={16}/>
                    <span>SUGGESTED TAGS</span>
                </TagsHeader>
                <SubRow>
                {
                    isSuggestedTagsOpen && allTags.filter((tag: ITag) => !tags?.find(innerTag => innerTag.uuid === tag.uuid))
                        .filter((tag: ITag) => allTagsIds?.find(innerTagId => innerTagId === tag.uuid))
                        .map((tag: ITag) => (<TagRow isSelected={false}>
                        <Tag 
                            uuid={tag.uuid} 
                            name={tag.name} 
                            color={tag.color} 
                            bgColor={tag.bgColor}/>
                        <IoAddOutline onClick={() => {
                            fetch(`http://146.59.44.6:8080/tagOwner/player/save${!!groupId ? '?groupId=' + groupId : ''}`,{ 
                                method: "POST",
                                mode: 'cors',
                                headers: { 'Content-Type': 'application/json'},
                                body: JSON.stringify({
                                    tagId: tag.uuid,playerUuid,
                                    playerId: playerUuid
                                })
                            }).then(body => body.json()
                            ).then(json => {
                                setTags((prevTags: ITag[]) => {
                                    prevTags = prevTags?.filter(innerTag => !!innerTag).filter((innerTag: ITag) => innerTag.uuid !== json?.uuid);
                                    const newItem = allTags.find((innerTag: ITag) => innerTag.uuid === json?.tagId);
                                    !!newItem && prevTags.push(newItem);
                                    return prevTags;
                                });
                            });
                        }}/>
                    </TagRow>))
                }
                </SubRow>
            </TagsContainerExtended>)
        }
        <TagsContainerExtended>
            <TagsHeader fontSize={14} fontWeight={400} onClick={() => setIsAllTagsOpen((prevState: boolean) => !prevState)} isClickable={true}>
                <Icon 
                    onClick={() => {}}
                    icon={isAllTagsOpen ? icons.UpArrowIcon : icons.DownArrowIcon}
                    height={16}/>
                <span>REST TAGS</span>
            </TagsHeader>
            <SubRow>
            {
                isAllTagsOpen && allTags.filter((tag: ITag) => !tags?.find(innerTag => innerTag.uuid === tag.uuid))
                    .filter((tag: ITag) => !allTagsIds?.find(innerTagId => innerTagId === tag.uuid))
                    .map((tag: ITag) => (<TagRow isSelected={false}>
                    <Tag 
                        uuid={tag.uuid} 
                        name={tag.name} 
                        color={tag.color} 
                        bgColor={tag.bgColor}/>
                    <IoAddOutline onClick={() => {
                        fetch(`http://146.59.44.6:8080/tagOwner/player/save${!!groupId ? '?groupId=' + groupId : ''}`,{ 
                            method: "POST",
                            mode: 'cors',
                            headers: { 'Content-Type': 'application/json'},
                            body: JSON.stringify({
                                tagId: tag.uuid,playerUuid,
                                playerId: playerUuid
                            })
                        }).then(body => body.json()
                        ).then(json => {
                            setTags((prevTags: ITag[]) => {
                                prevTags = prevTags?.filter(innerTag => !!innerTag).filter((innerTag: ITag) => innerTag.uuid !== json?.uuid);
                                const newItem = allTags.find((innerTag: ITag) => innerTag.uuid === json?.tagId);
                                !!newItem && prevTags.push(newItem);
                                return prevTags;
                            });
                        });
                    }}/>
                </TagRow>))
            }
            </SubRow>
        </TagsContainerExtended>
    </Container>);
};

const Container = styled.div`
    width: 840px;
    margin: 0 auto;
    color: ${colors.white};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const TagsContainerExtended = styled(TagsContainer)`
    padding: 16px;
    min-height: 41px;
`;