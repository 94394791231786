import { Button } from "@chakra-ui/react";
import styled from "styled-components";
import { colors } from "../../styles/colors";

export const StyledButton = styled(Button)<{color?: string, bgColor?: string}>`
    width: fit-content;
    line-height: 24px;
    font-size: 16px;
    padding: 8px 16px;
    cursor: ${p => p.isActive ? 'pointer' : 'default'};
    border-radius: 8px;
    background: ${p => p.bgColor || colors.soft};
    color: ${p => p.color || colors.whiteStrong};
    border: 1px solid ${colors.border};
    opacity: ${p => p.isActive ? 1 : 0.5};

    &:hover {
        opacity: ${p => p.isActive ? 0.8 : 0.5};
    }
`;