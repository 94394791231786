import React, { FC } from "react";
import styled from "styled-components";
import { colors } from "../../styles/colors";

interface ICard {
    background: string;
    figure: string;
    width?: number;
    height?: number;
    fontSize?: number;
}

export const Card: FC<ICard> = ({
    background,
    figure,
    width,
    height,
    fontSize
}) => {
    return (<CardContainter 
        background={background}
        width={width}
        height={height}
        fontSize={fontSize}>{ figure } </CardContainter>);
};

const CardContainter = styled.div<{background: string, width: number | undefined, height: number | undefined, fontSize?: number | undefined}>`
    width: ${p => p.width || 35}px !important;
    height: ${p => p.height || 50}px;
    background: ${p => p.background};
    color: ${colors.whiteStrong} !important;
    font-size: ${p => p.fontSize || 32}px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
`;