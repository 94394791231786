import React, { 
    FC, 
    useEffect, 
    useState, 
} from "react";
import { Header } from "../Header/Header";
import styled from "styled-components";
import { 
    Button, 
    Input, 
} from "@chakra-ui/react";
import { colors } from "../../styles/colors";
import { delay } from "./Register";

export const Login: FC = () => {

    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string>('');

    useEffect(() => {
        !!sessionStorage.getItem('user') && (window.location.href = '/');
    }, []);

    return (<>
        <Header itemSelected={6} />
        <LoginContainer>
            <div>
                <span>USERNAME</span>
                <Input width={300} fontSize={16} padding={8} borderRadius={8} onChange={event => {
                    error !== '' && setError('');
                    setUsername(event.target.value)
                }}/>
            </div>
            <div>
                <span>PASSWORD</span>
                <Input width={300} fontSize={16} padding={8} type={'password'} borderRadius={8} onChange={event => {
                    error !== '' && setError('');
                    setPassword(event.target.value)
                }}/>
            </div>
            <div>
                <Button isDisabled={!(!!username && !!password)} width={532} fontSize={21} padding={8} backgroundColor={colors.buttons.download} color={colors.white} borderRadius={8} onClick={() => {
                    if (!!username && !!password) {
                        fetch(`http://146.59.44.6:8080/user/login`,{ 
                            method: "POST",
                            mode: 'cors',
                            headers: { 'Content-Type': 'application/json'},
                            body: JSON.stringify({
                                username: username,
                                password: password
                            })
                        }).then(async res => {
                            if(res.ok) {
                                sessionStorage.setItem('user', JSON.stringify(await res.json()));
                                await delay(300);
                                window.location.href = '/';
                            } else {
                                setError('User with those credentials not found');
                            }
                        });
                    }
                }}>LOGIN</Button>
            </div>
        </LoginContainer>
        <ErrorDiv withError={!!error}>{error}</ErrorDiv>
    </>);
};

const LoginContainer = styled.div`
    padding: 32px;
    border-radius: 16px;
    border: 4px solid ${colors.white};
    color: ${colors.whiteStrong};
    font-size: 21px;
    line-height: 24px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 600px;
    margin-left: 50%;
    position: absolute;
    top: 50vh;
    transform: translateY(-50%) translateX(-50%);
    align-items: center;
    justify-content: center;

    > div {
        display: flex;
        gap: 16px;
        align-items: center;
        justify-content: center;

        > span {
            width: 200px;
        }
    }
`;

const ErrorDiv = styled.div<{withError?: boolean}>`
    padding: 8px;
    min-height: 16px;
    font-size: 16px;
    line-height: 21px;
    width: 672px;
    text-align: center;
    color: ${colors.errorBg};
    background: ${p => p.withError ? colors.white : 'inherit'};
    border-radius: 4px;
    flex-direction: column;
    gap: 8px;
    position: absolute;
    transform: translateX(-50%);
    bottom: 20px;
    margin-left: 50vw;
`;