import React, { FC } from "react";
import styled from "styled-components";
import { colors } from "../../styles/colors";

interface ISubheader {
    title: string;
};

export const Subheader: FC<ISubheader> = ({ title }) => {
    return (<SubheaderContainer>
        <h2>{ title }</h2>
    </SubheaderContainer>);
}

export const SubheaderContainer = styled.div`
    width: 100%;
    height: 100px;
    background-color: ${colors.background.secondary};
    margin-top: -100px;
    position: absolute;
    z-index: 500;
    opacity: 0.9;
    display: flex;
    items-align: center;
    padding: 30px;
    box-sizing: border-box;
    gap: 32px;
    justify-content: center;

    h2 {
        position: absolute;
        left: 0;
        margin: 0;
        margin-left: 50%;
        transform: translateX(-50%);
        line-height: 40px;
        color: ${colors.white};
        text-transform: uppercase;
    }
`;