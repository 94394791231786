import React, { 
    FC, 
    useEffect, 
    useState, 
} from 'react';
import { Header } from '../../Header/Header';
import styled from 'styled-components';
import { IUser } from '../../../types/IGroup.types';
import icons, { Icon } from '../../commons/Icon';
import { colors } from '../../../styles/colors';
import { StyledButton } from '../../commons/Button';

const types = ['admin', 'public', 'gold', 'active'];

export const Users: FC = () => {
    
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<IUser | null>();
    const [users, setUsers] = useState<IUser[]>([]);
    const [newNick, setNewNick] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [newEmail, setNewEmail] = useState<string>('');

    const user = JSON.parse(sessionStorage?.getItem('user') || '{}');
    const userId = user?.uuid;
    const groupId = user?.groupId;
    const isAdmin = user?.admin;

    const generateTickIcon = (action: string, user: IUser, selectedUser?: IUser | null) => (<Icon 
        width={24} 
        icon={(action === 'active' && user.active || action === 'admin' && user.admin || action === 'public' && user.isPublic || action === 'gold' && user.isGold) ? icons.TickIcon : icons.NoTickIcon} 
        onClick={() => {
            if (!!selectedUser) {
                return;
            }
            action === 'active' && (user.active = !user.active);
            action === 'admin' && (user.admin = !user.admin);
            action === 'public' && (user.isPublic = !user.isPublic);
            action === 'gold' && (user.isGold = !user.isGold);
            fetch(`http://146.59.44.6:8080/user/update${!!groupId ? '?groupId=' + groupId : ''}`, { 
                method: "PUT",
                mode: 'cors',
                headers: { 'Content-Type': 'application/json'},
                body: JSON.stringify(user)
            }).then(res => res.text())
            .then(text => JSON.parse(text))
            .then(json => {
                setUsers((prevState: IUser[]) => {
                    const newState: IUser[] = [];
                    prevState.forEach(item => {
                        if (item.uuid !== json.uuid) {
                            newState.push(item);
                        } else {
                            newState.push(json);
                        }
                    })
                    return newState;
                })
            });
        }} />);

    if (!userId) {
        window.location.href = '/login';
    } else if (!isAdmin) {
        window.location.href = '/';
    }

    useEffect(() => {
        fetch(`http://146.59.44.6:8080/user/all${!!groupId ? '?groupId=' + groupId : ''}`,{ 
                method: "GET",
                headers: { 'Content-Type': 'application/json'}
            }).then(res => res.text())
            .then(text => JSON.parse(text))
            .then(json => {
                setUsers(json);
                setIsLoading(false);
            });
    }, []);

    const isAvailable = users.filter(user => !user.uuid).length === 0;

    return (<>
        <Header itemSelected={10} />
        <MainContainer>
        { !!selectedUser && (<NewItem className='updateRow'>
            <div onClick={() => {
                if (!!selectedUser.password && selectedUser.password.length > 3) {
                    fetch(`http://146.59.44.6:8080/user/update${!!groupId ? '?groupId=' + groupId : ''}`, { 
                        method: "PUT",
                        mode: 'cors',
                        headers: { 'Content-Type': 'application/json'},
                        body: JSON.stringify(selectedUser)
                    }).then(res => res.text())
                    .then(text => JSON.parse(text))
                    .then(json => {
                        setUsers((prevState: IUser[]) => {
                            const newState: IUser[] = [];
                            prevState.forEach(item => {
                                if (item.uuid !== json.uuid) {
                                    newState.push(item);
                                } else {
                                    newState.push({
                                        ...json,
                                        password: null
                                    });
                                }
                            })
                            return newState;
                        });
                        setSelectedUser(null);
                    });
                }
            }}>
                <span>SAVE</span> 
                <Icon icon={icons.TickIcon} height={22} onClick={() => {}} fill={colors.whiteStrong}/>
            </div>
            <div>
                <label>NICK:</label>
                <input type="text" value={selectedUser.nick} onChange={(e) => {
                    setSelectedUser({
                        ...selectedUser,
                        nick: e?.target?.value
                    });
                }}/>
            </div>
            <div>
                <label>NEW PASSWORD:</label>
                <input type="text" value={selectedUser.password} onChange={(e) => {
                    setSelectedUser({
                        ...selectedUser,
                        password: e?.target?.value
                    })
                }}/>
            </div>
        </NewItem>) }
            <HeaderRow>
                <div>NICK</div>
                <div>
                    <div>ADMIN</div>
                    <div>PUBLIC</div>
                    <div>GOLD</div>
                    <div>ACTIVE</div>
                    <div>DELETE</div>
                </div>
            </HeaderRow>
        {
            users.sort(user => user.uuid !== selectedUser?.uuid ? 1 : -1).map((user: IUser) => (!!user.uuid ? (<UserDiv isActive={selectedUser?.uuid === user.uuid}>
                <div onClick={() => selectedUser?.uuid === user.uuid ? setSelectedUser(null) : setSelectedUser(user)}>{user.nick}</div>
                <IconWrapper active={user.active} public={user.isPublic} admin={user.admin} gold={user.isGold}>
                    {
                        types.map(type => generateTickIcon(type, user, selectedUser))
                    }
                    <Icon width={18} icon={icons.TrashIcon} onClick={() => {
                        /* eslint-disable */
                        if (confirm(`Are you sure you want to delete ${user.nick}?`)) {
                            fetch(`http://146.59.44.6:8080/user/delete${!!groupId ? '?groupId=' + groupId : ''}`, { 
                                method: "DELETE",
                                mode: 'cors',
                                headers: { 'Content-Type': 'application/json'},
                                body: JSON.stringify(user)
                            }).then(res => res.text())
                            .then(text => JSON.parse(text))
                            .then(json => {
                                setUsers((prevState: IUser[]) => {
                                    const newState: IUser[] = [];
                                    prevState.forEach(item => {
                                        if (item.uuid !== json.uuid) {
                                            newState.push(item);
                                        }
                                    })
                                    return newState;
                                })
                            })
                        }
                    }} />
                </IconWrapper>
            </UserDiv>) : (<NewItem>
                <div>
                    <Icon icon={icons.TrashIcon} height={22} onClick={() => {
                        setNewEmail('');
                        setNewNick('');
                        setNewPassword('');
                        setUsers((prevState: IUser[]) => {
                            const newState: IUser[] = [...prevState];
                            return newState.filter(user => !!user.uuid);
                        })
                    }}/>
                </div>
                <div>
                    <label>NICK:</label>
                    <input type="text" value={newNick} onChange={(e) => {
                        setNewNick(e?.target?.value);
                    }}/>
                </div>
                <div>
                    <label>PASSWORD:</label>
                    <input type="text" value={newPassword} onChange={(e) => {
                        setNewPassword(e?.target?.value);
                    }}/>
                </div>
                <div>
                    <label>E-MAIL:</label>
                    <input type="text" value={newEmail} onChange={(e) => {
                        setNewEmail(e?.target?.value);
                    }}/>
                </div>
            </NewItem>)))
        }
        <AddSection disabled={!isAvailable && (!newNick || !newPassword || !newEmail)}>
            <StyledButton width={150} bgColor={colors.buttons.download} opacity={1} onClick={() => {
                if (isAvailable) {
                    setUsers((prevState: IUser[]) => {
                        const newState: IUser[] = [...prevState];
                        newState.push({
                            uuid: null,
                            nick: ''
                        })
                        return newState;
                    })
                } else {
                    if (!!newNick && !!newPassword && !!newEmail) {
                        fetch(`http://146.59.44.6:8080/user/register${!!groupId ? '?groupId=' + groupId : ''}`,{ 
                            method: "POST",
                            mode: 'cors',
                            headers: { 'Content-Type': 'application/json'},
                            body: JSON.stringify({
                                nick: newNick,
                                password: newPassword,
                                email: newEmail
                            })
                        })
                        .then(res => res.text())
                        .then(text => JSON.parse(text))
                        .then(json => {
                            setNewEmail('');
                            setNewNick('');
                            setNewPassword('');
                            setUsers((prevState: IUser[]) => {
                                const newState: IUser[] = [];
                                prevState.forEach(item => {
                                    if (!!item.uuid) {
                                        newState.push(item);
                                    } else {
                                        newState.push(json);
                                    }
                                })
                                return newState;
                            })
                        })
                    }
                }
            }}>{ isAvailable ? 'ADD' : 'SAVE' }</StyledButton>
        </AddSection>
        </MainContainer>
    </>)

};

export const MainContainer = styled.div`
    margin-top: 127px;
    padding-top: 32px;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    opacity: 1;
    color: ${colors.whiteStrong};

    > div:not(.updateRow) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 4px;
        border-bottom: 1px solid ${colors.white};
    }

    > div.updateRow {
        margin-bottom: 24px;
    }

    > div.updateRow > div:first-child {
        padding: 8px 16px;
        width: fit-content;
        margin: 0 auto;
        border: 1px solid ${colors.white};
        cursor: pointer;
        border-radius: 8px;

        &:hover {
            opacity: 0.7;
        }
    }
`;

const HeaderRow = styled.div`
    font-size: 14px;

    > div {
        display: flex;
        justify-content: center;

        > div {
            width: 60px;
        }
    }
`;

const IconWrapper = styled.div<{ active?: boolean, public?: boolean, admin?: boolean, gold?: boolean}>`
    display: flex;

    > div:hover {
        opacity: 0.7;
        cursor: pointer;
    }

    > div:nth-child(1) {
        path {
            stroke: ${p => p.admin ? colors.success : colors.failureStrong};
        }
    }

    > div:nth-child(2) {
        path {
            stroke: ${p => p.public ? colors.success : colors.failureStrong};
        }
    }

    > div:nth-child(3) {
        path {
            stroke: ${p => p.gold ? colors.success : colors.failureStrong};
        }
    }

    > div:nth-child(4) {
        path {
            stroke: ${p => p.active ? colors.success : colors.failureStrong};
        }
    }

    > div {
        width: 60px;
        align-items: center;
        justify-content: center;
        display: flex;
    }
`;

const AddSection = styled.div<{disabled?: boolean}>`
    margin-top: 16px;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: none !important;

    button {
        margin: 0 auto;
    }

    ${p => p.disabled && `button:hover {
        cursor: default;
        opacity: 1;
    }`}
`;

const NewItem = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-self: flex-start;
    justify-self: flex-start;
    gap: 16px;

    span {
        margin-right: 16px;
    }

    > div {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-end;

        input {
            padding: 4px;
            width: 350px;
            font-size: 16px;
        }

        label {
            text-align: right;
            margin-right: 16px;
            font-size: 13px;
        }
    }

    > div:first-child {
        margin-top: 16px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

    }
`;

export const UserDiv = styled.div<{ isActive: boolean }>`
    background: ${p => p.isActive && colors.black};

    > div:first-child {
        width: 100%;
        cursor: pointer;
    }
`;