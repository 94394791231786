import React, { 
    FC, 
    useEffect, 
    useRef, 
    useState,
} from "react";

import { ButtonsRow } from "../PlayerHands/PlayerHand";
import { Header } from "../Header/Header";
import { StyledButton } from "../commons/Button";
import { colors } from "../../styles/colors";
import styled from "styled-components";
import IHand from "../../types/Hand.types";
import { HandDetails } from "../HandDetails/HandDetails";
import ICard, { COLOR, FIGURE } from "../../types/Card.types";
import IPlayer from "../../types/Player.types";
import { ACTION, TYPE } from "../../types/Action.types";
import { cloneDeep } from "lodash";
import IPlayerGlobal from "../../types/IPlayerGlobal";

const COLORS_BY_SHAPE: Record<string, COLOR> = {
    '♠' : COLOR.SPADES, 
    '♥' : COLOR.HEARTS, 
    '♣' : COLOR.CLUBS,
    '◆' : COLOR.DIAMOND
};

export const HandPokerSolver = require('pokersolver').Hand;

const mapActionType = (line: string) => {
    if (line.includes('베팅: 다이') || line.includes('[997]')) {
        return {
            type: ACTION.FOLD,
            amount: 0
        };
    } else if (line.includes('베팅: 콜')) {
        return {
            type: ACTION.CALL,
            amount: parseInt(line?.split('원')?.[0]?.split('-')?.[1]?.replaceAll(',', '') || '0')
        };
    } else if (line.includes('베팅: 체크')) {
        return {
            type: ACTION.CHECK,
            amount: 0
        };
    } else if (line.includes('베팅: [하프]')) {
        return {
            type: ACTION.BET,
            amount: parseInt(line?.split('원')?.[0]?.split('(')?.[1]?.replaceAll(',', '') || '0'),
            betType: TYPE.HALF
        };
    } else if (line.includes('베팅: [쿼터]')) {
        return {
            type: ACTION.BET,
            amount: parseInt(line?.split('원')?.[0]?.split('(')?.[1]?.replaceAll(',', '') || '0'),
            betType: TYPE.QUARTER
        };
    } else if (line.includes('베팅: [풀]')) {
        return {
            type: ACTION.BET,
            amount: parseInt(line?.split('원')?.[0]?.split('(')?.[1]?.replaceAll(',', '') || '0'),
            betType: TYPE.POT
        };
    } else if (line.includes('베팅: [올인]')) {
        return {
            type: ACTION.ALL_IN,
            amount: parseInt(line?.split('원')?.[0]?.split('(')?.[1]?.replaceAll(',', '') || '0')
        };
    }

    return {
        type: ACTION.BET,
        amount: parseInt(line?.split('원')?.[0]?.split('(')?.[1]?.replaceAll(',', '') || '0'),
        betType: TYPE.MANUAL
    };
} 

export const AddHand: FC = () => {

    const [value, setValue] = useState<string>('');
    const [hand, setHand] = useState<IHand | null>(null);
    const [allPlayers, setAllPlayers] = useState<IPlayerGlobal[]>([]);
    const [isSaved, setIsSaved] = useState<boolean>(false);
    const [isDeleted, setIsDeleted] = useState<boolean>(false);
    const [isInDatabase, setIsInDatabase] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    const myRef = useRef<null | HTMLDivElement>(null);
    const userObj = JSON.parse(sessionStorage?.getItem('user') || '{}');
    const userId = userObj?.uuid;
    const groupId = userObj?.groupId;
    const isPublic = userObj?.isPublic;
    
    if (!userId) {
        window.location.href = '/login';
    }

    const fetchDataFromClipboard = async () => {
        setValue('');
        setHand(null);

        try {
            const clipboardItems = await navigator.clipboard.read();
            const blob = await clipboardItems?.[0]?.getType('text/html');
            setValue(await blob?.text());
        } catch {
            setValue('');
            setHand(null);
        }
    };

    useEffect(() => {
        const anteActions = [];
        const actualHand: IHand = {
            title: '',
            bb: 0,
            sb: 0,
            players: [],
            blind: [],
            preflop: [],
            flop: [],
            turn: [],
            river: [],
            board: [],
            pot: [],
            heroCards: [],
            date: ''
        };

        if (!!value) {
            setIsInDatabase(false);
            setIsSaved(false);
            setIsError(false);
            setIsDeleted(false);
            try {
                const container = document.getElementById('copiedHtml');
                const mainTable = container?.getElementsByTagName('table')?.[0];
                const insideTable = container?.querySelectorAll('td.double-table')?.[0]?.getElementsByTagName('table')?.[0];
                const mainRowTds = mainTable?.getElementsByTagName('tr')?.[1]?.getElementsByTagName('td');
                const titleTd = mainRowTds?.[0];
                const dateTd = mainRowTds?.[1];
                if (!!titleTd) {
                    actualHand.title = titleTd.textContent?.trim() || '';
                }
                if (!!dateTd) {
                    let datetime = dateTd.textContent?.split(' ') || [];
                    let isPm = false;
                    let isMidnight = false;
                    if (datetime.length > 2) {
                        const centeredItem = datetime[1];
                        isPm = centeredItem.includes('후');
                        isMidnight = datetime[2].startsWith('12:');
                        datetime = [datetime[0], datetime[2]];
                    }
                    if (datetime[1].length === 7) {
                        datetime[1] = '0' + datetime[1];
                    }
                    const dateObject = new Date(`${datetime[0] + ' ' + datetime[1]}+00:00` + (isPm ? "-12:00" : "+0:00"));
                    const isoDate = dateObject.toISOString();
                    let finalDateString = `${isoDate.split('T')[0]} ${isoDate.split('T')[1].split('.000')[0]}`
                    if (isMidnight) {
                        finalDateString = finalDateString.replaceAll(' 12:', ' 00:');
                    }
                    actualHand.date = finalDateString;
                }

                const trs = insideTable?.getElementsByTagName("tr");
                if (!!trs) {
                    for (let i = 0; i < trs?.length; i++) {
                        const tds = trs[i]?.getElementsByTagName("td");

                        const cards: ICard[] = [];
                        let actualStack = 0;
                        let balance = 0;
                        const allCards: string[] = [];

                        if (!!tds && !!tds?.[1] && !!tds?.[1]?.getElementsByTagName('p')?.[0] && !!tds?.[1]?.getElementsByTagName('p')?.[0].innerHTML) {
                            const lines = tds[1].innerHTML.split('<br style="padding: 0px; margin: 0px;">');

                            if (!!lines?.[0]) {
                                const cardsString = lines?.[0]
                                    .replaceAll('<font color="red" style="padding: 0px; margin: 0px;">', '')
                                    .replaceAll('<p align="left" style="padding: 0px; margin: 0px;">', '')
                                    .replaceAll('</font>', '')
                                    .replaceAll('<span>&nbsp;</span>', ' ')
                                    .split('[')[0];

                                if (!!cardsString && cardsString.length > 4) {
                                    const cardsTotal = cardsString.split(' ');
                                    const figure1 = isNaN(parseInt(cardsTotal[0].slice(1))) ? cardsTotal[0].slice(1) : 'F' + cardsTotal[0].slice(1);
                                    const figure2 = isNaN(parseInt(cardsTotal[1].slice(1))) ? cardsTotal[1].slice(1) : 'F' + cardsTotal[1].slice(1);
                                    cards.push({
                                        color: COLORS_BY_SHAPE[cardsTotal[0][0]],
                                        figure: FIGURE[figure1 as keyof typeof FIGURE]
                                    });
                                    cards.push({
                                        color: COLORS_BY_SHAPE[cardsTotal[1][0]],
                                        figure: FIGURE[figure2 as keyof typeof FIGURE]
                                    });

                                    if (actualHand.board.length < cardsTotal.length - 3) {
                                        const lenght = actualHand.board.length;
                                        for (let j = lenght + 2; j < cardsTotal.length; j++) {
                                            if (cardsTotal[j].length >= 2) {
                                                const actualFigure = isNaN(parseInt(cardsTotal[j].slice(1))) ? cardsTotal[j].slice(1) : 'F' + cardsTotal[j].slice(1);
                                                actualHand.board.push({
                                                    color: COLORS_BY_SHAPE[cardsTotal[j][0]],
                                                    figure: FIGURE[actualFigure as keyof typeof FIGURE],
                                                    boardId: actualHand.board.length
                                                });
                                            }
                                        }
                                    }
                                }
                            }

                            if (lines.length > 1) {
                                const edittedLine = lines[1].replaceAll(',','');
                                const sb = edittedLine.split('SB:')[1]?.split('원')[0];
                                actualHand.sb = parseInt(sb);
                                const bb = edittedLine.split('BB:')[1]?.split('원')[0];
                                actualHand.bb = parseInt(bb);
                                actualStack = parseInt(edittedLine.replaceAll(',','').split('Credit:')[1]?.split('원')[0]);
                            }

                            if (!!lines?.[3]) {
                                anteActions.push({
                                    playerId: actualHand.players.length,
                                    nick: tds[0]?.textContent || '',
                                    type: ACTION.ANTE,
                                    time: 0,
                                    amount: actualHand.bb,
                                    amountInBB: 1
                                });
                            }

                            if (lines?.[0].includes('(Big Blind)')) {
                                actualHand.blind.push({
                                    playerId: actualHand.players.length,
                                    nick: tds[0]?.textContent || '',
                                    type: ACTION.BB,
                                    time: 0,
                                    amount: actualHand.bb,
                                    amountInBB: 1
                                });
                            }

                            if (lines?.[0].includes('(Small Blind)')) {
                                actualHand.blind.push({
                                    playerId: actualHand.players.length,
                                    nick: tds[0]?.textContent || '',
                                    type: ACTION.SB,
                                    time: 0,
                                    amount: actualHand.sb,
                                    amountInBB: actualHand.sb / actualHand.bb
                                });
                            }

                            if (lines?.[0].includes('(Entry Fee)')) {
                                actualHand.blind.push({
                                    playerId: actualHand.players.length,
                                    nick: tds[0]?.textContent || '',
                                    type: ACTION.BB,
                                    time: 0,
                                    amount: actualHand.bb,
                                    amountInBB: 1
                                });
                            }

                            const playerId = actualHand.players.length;

                            for (let lineIdx = 6; lineIdx < lines.length; lineIdx++) {
                                const actualLine = lines[lineIdx];

                                if (actualLine?.includes('[0][')) {
                                    const actionProps = mapActionType(actualLine);
                                    actualHand.preflop.push({
                                        actionId: parseInt(actualLine?.split('[0][')?.[1].split(']')?.[0] || '0'),
                                        playerId: playerId,
                                        nick: tds[0]?.textContent || '',
                                        type: actionProps.type,
                                        betType: actionProps.betType,
                                        amount: actionProps.amount,
                                        amountInBB: Math.round((actionProps?.amount || 0) * 10 / actualHand.bb) / 10,
                                        time: parseInt(actualLine?.split('] [')?.[1]?.split('ms')?.[0] || '0')
                                    });
                                }
                                if (actualLine?.includes('[1][')) {
                                    const actionProps = mapActionType(actualLine);
                                    actualHand.flop.push({
                                        actionId: parseInt(actualLine?.split('[1][')?.[1].split(']')?.[0] || '0'),
                                        playerId: playerId,
                                        nick: tds[0]?.textContent || '',
                                        type: actionProps.type,
                                        betType: actionProps.betType,
                                        amount: actionProps.amount,
                                        amountInBB: Math.round((actionProps?.amount || 0) * 10 / actualHand.bb) / 10,
                                        time: parseInt(actualLine?.split('] [')?.[1]?.split('ms')?.[0] || '0')
                                    });
                                }
                                if (actualLine?.includes('[2][')) {
                                    const actionProps = mapActionType(actualLine);
                                    actualHand.turn.push({
                                        actionId: parseInt(actualLine?.split('[2][')?.[1].split(']')?.[0] || '0'),
                                        playerId: playerId,
                                        nick: tds[0]?.textContent || '',
                                        type: actionProps.type,
                                        betType: actionProps.betType,
                                        amount: actionProps.amount,
                                        amountInBB: Math.round((actionProps?.amount || 0) * 10 / actualHand.bb) / 10,
                                        time: parseInt(actualLine?.split('] [')?.[1]?.split('ms')?.[0] || '0')
                                    });
                                }
                                if (actualLine?.includes('[3][')) {
                                    const actionProps = mapActionType(actualLine);
                                    actualHand.river.push({
                                        actionId: parseInt(actualLine?.split('[3][')?.[1].split(']')?.[0] || '0'),
                                        playerId: playerId,
                                        nick: tds[0]?.textContent || '',
                                        type:  actionProps.type,
                                        betType: actionProps.betType,
                                        amount: actionProps.amount,
                                        amountInBB: Math.round((actionProps?.amount || 0) * 10 / actualHand.bb) / 10,
                                        time: parseInt(actualLine?.split('] [')?.[1]?.split('ms')?.[0] || '0')
                                    });
                                }
                            }

                            if (tds.length > 2) {
                                balance = parseInt(tds[2]?.textContent?.replaceAll(',', '') || '0');
                            }
                        }

                        if (!!tds && !!tds?.[0] && !!tds[0].textContent) {
                            actualHand.board.forEach(boardCard => allCards.push(boardCard.figure + boardCard.color));
                            const actualAllCards: string[] = allCards.concat([]);
                            cards.forEach(card => actualAllCards.push(card.figure + card.color));
                            const handSolver = HandPokerSolver.solve(actualAllCards);

                            const object: IPlayer = {
                                nick: tds[0]?.textContent,
                                id: actualHand.players.length,
                                stack: actualStack,
                                isHero: false,
                                cards: cards,
                                tags: []
                            };

                            if (balance !== 0) {
                                object.balance = balance;
                                object.result = handSolver.name;
                            }

                            actualHand.players.push(object);
                        }
                    }
                }


                actualHand.preflop = actualHand.preflop.sort((a, b) => (a?.actionId || 0) - (b?.actionId || 0));
                actualHand.flop = actualHand.flop.sort((a, b) => (a?.actionId || 0) - (b?.actionId || 0));
                actualHand.turn = actualHand.turn.sort((a, b) => (a?.actionId || 0) - (b?.actionId || 0));
                actualHand.river = actualHand.river.sort((a, b) => (a?.actionId || 0) - (b?.actionId || 0));

                const playersIds = cloneDeep(actualHand.preflop)
                    .map(action => action.playerId)
                    .filter((value, index, array) => array.indexOf(value) === index);

                const previousState = cloneDeep(actualHand.players);
                const blindPreviousState = cloneDeep(actualHand.blind);
                actualHand.players = [];
                actualHand.blind = [];
                for (let pId = 0; pId < playersIds.length; pId++) {
                    const actualBlindAction = anteActions.find(blindAction => blindAction.playerId === playersIds[pId]);
                    !!actualBlindAction && actualHand.blind.push(actualBlindAction);
                }
                for (let pId = 0; pId < playersIds.length; pId++) {
                    const actualPlayer = previousState.find(player => player?.id === playersIds[pId]);
                    !!actualPlayer && actualHand.players.push({
                        positionId: pId,
                        ...actualPlayer
                    });

                    const actualBlindAction = blindPreviousState.find(blindAction => blindAction.playerId === playersIds[pId]);
                    !!actualBlindAction && actualHand.blind.push(actualBlindAction);
                }

                for (let aId = 0; aId < actualHand.blind.length; aId++) {
                    actualHand.blind[aId].actionId = aId;
                }


                actualHand.pot.push((cloneDeep(actualHand?.blind)?.map(action => action.amount).reduce((sum, current) => (sum || 0) + (current || 0), 0) || 0));
                actualHand.pot.push(actualHand.pot[actualHand.pot.length - 1] + (cloneDeep(actualHand?.preflop)?.map(action => action.amount).reduce((sum, current) => (sum || 0) + (current || 0), 0) || 0));
                actualHand.pot.push(actualHand.pot[actualHand.pot.length - 1] + (cloneDeep(actualHand?.flop)?.map(action => action.amount).reduce((sum, current) => (sum || 0) + (current || 0), 0) || 0));
                actualHand.pot.push(actualHand.pot[actualHand.pot.length - 1] + (cloneDeep(actualHand?.turn)?.map(action => action.amount).reduce((sum, current) => (sum || 0) + (current || 0), 0) || 0));
                actualHand.pot.push(actualHand.pot[actualHand.pot.length - 1] + (cloneDeep(actualHand?.river)?.map(action => action.amount).reduce((sum, current) => (sum || 0) + (current || 0), 0) || 0));
                

                if (!!actualHand && actualHand?.players?.length > 0) {
                    fetch(`http://146.59.44.6:8080/hand${!!groupId ? '?groupId=' + groupId : ''}`, { 
                        method: "POST",
                        mode: 'cors',
                        headers: { 'Content-Type': 'application/json'},
                        body: JSON.stringify({
                            userId: userId,
                            isPublic: isPublic || false,
                            ...actualHand,
                            date: actualHand.date.trim().replaceAll('^[0-9\-\:]', '')
                        })
                    }).then(res => {
                        if (res.ok) {
                            return res.json()
                        } else {
                            return ''
                        }
                    }).then(json => {
                        json?.new ? setIsSaved(true) : setIsInDatabase(true);
                        setHand(json);
                    }).catch(e => setIsError(true));
                }

                actualHand.players.forEach(player => {
                    fetch(`http://146.59.44.6:8080/player${!!groupId ? '?groupId=' + groupId : ''}`,{ 
                        method: "POST",
                        mode: 'cors',
                        headers: { 'Content-Type': 'application/json'},
                        body: JSON.stringify({
                            playerNick: player.nick
                        })
                    })
                });

                setHand(actualHand);
                !!myRef?.current && myRef?.current?.scrollIntoView({
                    behavior: 'smooth'
                });

            } catch {
                setValue('');
                setHand(null);
            }
        }
    }, [value]);

    useEffect(() => {
        fetch(`http://146.59.44.6:8080/player${!!groupId ? '?groupId=' + groupId : ''}`,{ 
            method: "GET",
            headers: { 'Content-Type': 'application/json'}
        }).then(res => res.ok ? res.json() : [])
        .then(json => {
            setAllPlayers(json);
        });

    }, []);

    return (<>
        <Header itemSelected={0} />
        <First deleteOption={true}>
            <StyledButton onClick={fetchDataFromClipboard}
                backgroundColor={colors.buttons.download}
                isActive={true}>
                GENERATE HAND FROM CLIPBOARD
            </StyledButton>
        </First>
        { isDeleted && (<InfoContainer color={colors.black} innerBgColor={colors.success} deleteOption={true}>
            <div>HAND DELETED SAVED</div>
        </InfoContainer>)}
        <Column 
            bgColor={colors.background.secondary}  
            ref={myRef}>
        { 
            !!hand && (isSaved || isInDatabase || isError) && (<Row bgColor={colors.background.secondary} padding={8}>
                <InfoContainer 
                    innerBgColor={isError ? colors.errorBg : (isInDatabase ? colors.infoBg : colors.success)} 
                    color={isError ? colors.whiteStrong : colors.black }>
                { isInDatabase && (<div>HAND ALREADY IN DATABASE</div>) } 
                { isError && (<div>ERROR DURING SAVING HAND</div>) }
                { isSaved && <div>HAND SUCCESSFULLY SAVED</div>}
                </InfoContainer>
            </Row>)
        }
        { !!hand && hand?.players?.length > 0 ? 
            <HandDetails 
                allPlayers={allPlayers}
                title={hand.title}
                uuid={hand.uuid}
                players={hand.players}
                bb={hand.bb}
                sb={hand.sb}
                pot={hand.pot}
                blind={hand.blind.sort((a, b) => (a?.actionId || 0) - (b?.actionId || 0))}
                preflop={hand.preflop.sort((a, b) => (a?.actionId || 0) - (b?.actionId || 0))}
                flop={hand.flop.sort((a, b) => (a?.actionId || 0) - (b?.actionId || 0))}
                turn={hand.turn.sort((a, b) => (a?.actionId || 0) - (b?.actionId || 0))}
                river={hand.river.sort((a, b) => (a?.actionId || 0) - (b?.actionId || 0))}
                board={hand.board.sort((h1: ICard, h2: ICard) => (h1?.boardId || 0) - (h2?.boardId || 0))} 
                date={hand.date}
                heroCards={hand.heroCards} 
                fullRadius={!isInDatabase && !isError && !isSaved}
                heroNick={''} /> : 'NO PREPARED HAND TO PRESENT' }
        </Column>
        { !!hand && (<ExtendedButtonsRow gap={16}>
                <StyledButton 
                    onClick={() => {
                        setValue('');
                        setHand(null);
                    }}
                    backgroundColor={colors.buttons.details}
                    color={colors.border}
                    isActive={true}>
                    CLEAR
                </StyledButton>
                {
                    !!isSaved && (<StyledButton 
                        onClick={() => {
                            fetch(`http://146.59.44.6:8080/hand${!!groupId ? '?groupId=' + groupId : ''}`,{ 
                                method: "DELETE",
                                mode: 'cors',
                                headers: { 'Content-Type': 'application/json'},
                                body: JSON.stringify(hand)
                            }).then(res => {
                                if (res.ok) {
                                    return res.text()
                                } else {
                                    return ''
                                }
                            }).then(text => text.trim().length === 0 ? setIsError(true) : setIsDeleted(true))
                            .catch(e => setIsError(true));
                            setValue('');
                            setHand(null);
                        }}
                        backgroundColor={colors.buttons.delete}
                        color={colors.border}
                        isActive={true}>
                        DELETE
                    </StyledButton>)
                }
            </ExtendedButtonsRow>)
        }
        <Row bgColor={colors.whiteStrong} color={colors.border}>
        { !!value && (<div id='copiedHtml' dangerouslySetInnerHTML={{__html: value}}></div>) }
        </Row>
    </>);
};

const Row = styled.div<{bgColor?: string, color?: string, padding?: number}>`
    display: flex;
    align-items: center;
    padding: ${p => p.padding || 32}px;
    justify-content: center;
    background-color: ${p => p.bgColor};

    max-width: 100vw;
    overflow: scroll;
    box-sizing: border-box;

    font-family: sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;

    color: ${p => p.color || colors.white};
`;

const Column = styled(Row)`
    flex-direction: column;
`;

const First = styled(Row)<{deleteOption?: boolean}>`
    margin-top: 127px;
    background-color: ${colors.background.secondaryWithOpacity};
    padding-bottom: ${p => p.deleteOption ? '16px' : '0' };
`;

const ExtendedButtonsRow = styled(ButtonsRow)`
    width: 100%;
    items-align: center;
    justify-content: center;
`;

const InfoContainer = styled.div<{innerBgColor: string, color: string, deleteOption?: boolean}>`
    background-color: ${p => p.innerBgColor || colors.infoBg};
    color: ${p => p.color || colors.border};
    border-radius: ${p => p.deleteOption ? '' : '8px 8px 0 0'};
    width: ${p => p.deleteOption ? '100%' : '840px'};
    text-align: center;
    line-height: 24px;
    font-size: 16px;
    position: ${p => p.deleteOption ? ''  : 'absolute'};

    > div {
        padding: 8px 16px;
    }
`;