import React, { FC } from "react";

import ITag from "../../types/Tag.types";
import { styled } from "styled-components";
import { colors } from "../../styles/colors";

export const Tag: FC<ITag> = ({
    uuid,
    name,
    color,
    bgColor,
    isSelected,
    onClick,
    isClickable,
    fontSize,
}) => {
    return (<TagContainer
        fontSize={fontSize}
        onClick={() => !!onClick && onClick({
            uuid,
            name: name,
            color: color,
            bgColor: bgColor
        })}
        color={color}
        bgColor={bgColor}
        isSelected={isSelected}
        isClickable={isClickable}
        cursor={(!!onClick || isClickable) ? 'pointer' : null}>
        { name }
    </TagContainer>);
};

const TagContainer = styled.div<{color: string, bgColor: string, isSelected?: boolean, cursor?: string | null, isClickable?: boolean, fontSize?: number}>`
    padding: 4px 8px;
    color: ${p => p.color || colors.white};
    background-color: ${p => p.bgColor || colors.black};
    border-radius: 8px;
    font-size: ${p => p.fontSize || 16}px;
    line-height: ${p => p.fontSize ? (p.fontSize + 4) : 21}px;
    text-decoration: ${p => p.isSelected && 'underline'};
    cursor: ${p => p.cursor};

    &:hover {
        text-decoration: ${p => p.isClickable && 'underline'};
    }
`;